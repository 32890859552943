import { combineReducers } from "redux";
import ResultVideos from "./ResultVideos";
import Categories from "./Categories";
import Courses from "./Courses";
import SubCategories from "./SubCategories";
import Slug from "./Slugs";
import Service from "./Service";
import Cart from "./Cart";
import Auth from "./Auth";
import User from "./User";
import Blogs from "./Blogs";
const rootReducer = combineReducers({
  result: ResultVideos,
  categories: Categories,
  courses: Courses,
  subcategories: SubCategories,
  slug: Slug,
  service: Service,
  cart: Cart,
  auth: Auth,
  user: User,
  blogs: Blogs
});

// const initialState = appReducer({}, {});

// const rootReducer = (state, action) => {
//   if (action.type === LOGOUT) {
//     ;
//     state = initialState;
//     // for all keys defined in your persistConfig(s)
//     localStorage.removeItem("persist:course-abc");
//     localStorage.removeItem("user");
//     // storage.removeItem('persist:otherKey')

//     return appReducer(undefined, action);
//   }
//   return appReducer(state, action);
// };

export default rootReducer;
