import { fork, all, takeLatest, call, put } from "redux-saga/effects";
import {
  SUBCATEGORY_LIST_REQUEST,
  SUBCATEGORY_LIST_SUCCESS
} from "../constants";
import { subCategoriesListAction } from "../services/api";
function* watchSubCategoryList() {
  yield takeLatest(SUBCATEGORY_LIST_REQUEST, getSubCategoryFromAPI);
}
function* getSubCategoryFromAPI() {
  const subCategoryList = yield call(subCategoriesListAction);

  if (subCategoryList.data.statusCode === 200) {
    yield put({
      type: SUBCATEGORY_LIST_SUCCESS,
      payload: subCategoryList.data.data
    });
  }
}
export default function* subCategoriesSaga() {
  yield all([fork(watchSubCategoryList)]);
}
