import React, { Component } from 'react'
import IdleTimer from 'react-idle-timer'
import {IdleTimeOutModal} from '../components/IdleTimer/idleTimerModel'
import { logout } from "../../src/redux/action/Auth";

export default class IdleTimerLogout extends Component {
  constructor(props) {
    super(props)
    this.idleTimer = null
    this.handleOnAction = this.handleOnAction.bind(this)
    this.handleOnActive = this.handleOnActive.bind(this)
    this.handleOnIdle = this.handleOnIdle.bind(this)

    this.state = {
        timeout:1000 * 5 * 1,
        showModal: false,
        userLoggedIn: false,
        isTimedOut: false
    }
    
    this.handleClose = this.handleClose.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
  }
  handleClose() {
    this.setState({showModal: false})
  }
  logout = () => {
    localStorage.clear();
// you can also like localStorage.removeItem('Token');
    window.location.href = "/";
  }
  handleLogout() {
    this.setState({showModal: false})
    this.logout();
   // this.props.history.push('/')
  }
 
  render() {
    return (
      <div>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          timeout={1000 * 60 * 5}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />
        {/* <IdleTimeOutModal 
                    showModal={this.state.showModal} 
                    handleClose={this.handleClose}
                    handleLogout={this.handleLogout}
                /> */}
        {/* your app here */}
      </div>
    )
  }

  handleOnAction (event) {
    console.log('user did something', event)
    this.setState({isTimedOut: false})
  }

  handleOnActive (event) {
    console.log('user is active', event)
    console.log('time remaining', this.idleTimer.getRemainingTime())
    this.setState({isTimedOut: false})
  }

  handleOnIdle (event) {
    console.log('user is idle', event)
    console.log('last active', this.idleTimer.getLastActiveTime())
    localStorage.clear();
    window.location.href = "/";
    // console.log('user is idle', e)
    // const isTimedOut = this.state.isTimedOut
    // if (isTimedOut) {
    //     localStorage.clear();
    //     // you can also like localStorage.removeItem('Token');
    //         window.location.href = "/";
    // } else {
    //   this.setState({showModal: true})
    //   this.idleTimer.reset();
    //   this.setState({isTimedOut: true})
    // }
  }
}