export const initialValues = {
  userId: "",
  paymentType: "",
  cc: "",
  cvv: "",
  cardfirstName: "",
  cardlastName: "",
  expire: "",
  expireMonth: "01",
  expireYear: "2021",
  amount: "",
  cardzip: "",
  date: "",
  unit: "days",
  length: 0,
  instalments: "",
  userDetails: {
    account_type: "parent",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    password: "",
    student_email: "",
    parent_details: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: {
        street: "",
        apartment: "",
        city: "",
        state: "New York",
        zipcode: ""
      }
    },
    status: "active"
  },
  order: {
    order_amount: 0,
    discount: 0,
    order_total: 0
  },
  orderDetails: {
    courses: [
      {
        courseId: "",
        categoryId: "",
        subCategoryId: "",
        price: 0,
        disc: 0,
        name: ""
      }
    ]
  }
};

// export const initialValues = {
//   account_type: "",
//   firstName: "",
//   lastName: "",
//   phone: "",
//   email: "",
//   password: "",
//   student_email: "",
//   parent_details: {
//     firstName: "",
//     lastName: "",
//     email: "",
//     phone: "",
//     address: {
//       street: "",
//       apartment: "",
//       city: "",
//       state: "",
//       zipcode: ""
//     }
//   },
//   status: "active",

//   userId: "",
//   cc: "",
//   cvv: "",
//   expire: "",
//   amount: ""

//   // terms: false
// };

// const data = {
//   amount: 11000,
//   cc: "987456321111",
//   cvv: "321",
//   date: "",
//   expire: "12/24",
//   instalments: "",
//   paymentType: "Full",
//   unit: "",
//   userId: "",
//    userDetails: {
//     account_type: "student",
//     email: "parent@yopmail.com",
//     firstName: "abc",
//     lastName: "test",
//     password: "1234567",
//     phone: "",
//     status: "active",
//     student_email: "test@yopmail.com",
//     userId: "",
//     parent_details: {
//       email: "parentTest@yopmail.com",
//       firstName: "xyz",
//       lastName: "test",
//       phone: "07845123655",
//       address: {
//         apartment: "2",
//         city: "Dehradun",
//         state: "UK",
//         street: "4",
//         zipcode: "248001"
//       }
//     }
//   },
//   order: {
//     discount: 1000,
//     order_amount: 12000,
//     order_total: 11000
//   },
//   orderDetails: {
//     courses: [
//       {
//         categoryId: "60a8b9ba88be7034112cde6e",
//         courseId: "60b7362642a9b444c60eb6bd",
//         disc: 1000,
//         name: "ddd",
//         price: 11000,
//         subCategoryId: "60b71df342a9b444c60eb69b"
//       }
//     ]
//   },

// };
// length will be 10
