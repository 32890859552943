import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  GET_CART_REQUEST,
  GET_CART_SUCCESS,
  EMPTY_CART,
  SHIFT_CART,
  CHANGE_PAYMENT_TYPE
} from "../constants";
const initialState = {
  isLoading: false,
  items: [],
  type: null
  //cart: {}
};
// const proccessIncomingCart = (cart, newCart) => {
//   let cartItems = [...newCart, ...cart];
//   ;
//   cartItems = cartItems.filter((m, idx, self) => self.indexOf(m) === idx);
//   return cartItems;
// };
const CartReducer = (
  state = initialState,
  { type = {}, payload = null } = {}
) => {
  switch (type) {
    case ADD_TO_CART:
      return {
        ...state,
        items: [payload],
        type: {},
        isLoading: false
      };
    case REMOVE_FROM_CART:
      return {
        ...state,
        items: [...state.items].filter(m => m.courseId !== payload?.id),
        isLoading: false
      };
    case EMPTY_CART:
      return {
        //initialState
        ...state,
        items: []
      };
    case CHANGE_PAYMENT_TYPE:
      return {
        ...state,
        type: payload,
        isLoading: false
      };
    case GET_CART_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case GET_CART_SUCCESS:
      return {
        ...state,
        isLoading: false,
        //items: proccessIncomingCart(state.items, payload)
        items: payload
      };
    case SHIFT_CART:
      return {
        ...state,
        items: []
      };
    default:
      return state;
  }
};
export default CartReducer;
