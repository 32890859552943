import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Container } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import GoogleReview from "../../components/google-review/google-review";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Slider from "../../components/slider/slider";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { getSubCategoryList } from "../../redux/action/SubCategories";
import "./home.scss";
import Courses from "./components/courses";
import SEO from "../../components/seo";
import { baseUrl } from "../../ennviroment";

const Home = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [pageData, setPageData] = useState("");
  const [pageDatarefund, setPageDatarefund] = useState("");

  const [scroll, setScroll] = useState(false);
  const [metaTags, setMetaTags] = useState({});
  const { subCategoryList } = useSelector((state) => {
    return state.subcategories;
  });

  useEffect(() => {
    dispatch(getSubCategoryList());
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 700);
    });
  }, []);

  useEffect(() => {
    axios
      .get(
        `${baseUrl}api/v1/page/get-page?slug=home-middle-content`
      )
      .then((res) => {
        setMetaTags(res.data.data.data[0]);
        setPageData(res.data.data.data[0].description);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    axios
      .get(
        `${baseUrl}api/v1/page/get-page?slug=refund-policy`
      )
      .then((res) => {
        setMetaTags(res.data.data.data[0]);
        setPageDatarefund(res.data.data.data[0].description);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handleOnSelect = (item) => {
    // history.push("/service/" + item.slug);
    // history.push("/" + item.slug);
    history.push({
      pathname: `/${item.slug}`,
      state: { id: item._id },
    });
  };
  return (
    <>
      {metaTags&& Object.keys(metaTags).length ? (
        <SEO
          title={metaTags.meta_title}
          description={metaTags.meta_description}
          keywords={metaTags.meta_keywords}
        />
      ) : null}

      <section className="main-banner">
        <Container>
          <Row className="align-items-center">
            <Col md={6}>
              <h2>
                Beast Your
                <br /> Test With Us
              </h2>
              <form className="search-boxin">
                <div className="input-group">
                  <div style={{ width: "100%" }}>
                    <ReactSearchAutocomplete
                      items={subCategoryList.data}
                      fuseOptions={{
                        useExtendedSearch: true,
                        keys: ["title"],
                        includeScore: true,
                        includeMatches: true,
                      }}
                      showIcon={false}
                      placeholder=""
                      resultStringKeyName="title"
                      onSelect={handleOnSelect}
                    />
                    <input
                      type="button"
                      className="search-here btn"
                      value="Find Your Prep"
                    />
                  </div>
                </div>
              </form>
            </Col>
            <Col md={6}>
              <div className="prep-outer">
                {subCategoryList?.data && subCategoryList?.data.length > 0
                  ? subCategoryList?.data.slice(0, 3).map((val) => (
                      // <Link to={`/service/${val.slug}`}>
                      <Link
                        to={{
                          pathname: `/${val.slug}`,
                          state: { id: val._id },
                        }}
                      >
                        <div key={val._id} className="boxes">
                          <h3>{val.title}</h3>
                          <p>In Person | Live Online</p>
                          <a href="#" className="icon-arrow">
                            <img
                              src="/images/green-right-arrow.svg"
                              alt="icon"
                            />
                          </a>
                        </div>
                      </Link>
                    ))
                  : null}
              </div>
            </Col>
          </Row>
        </Container>
        <h1 className="watermark-text ">Tutoring Center</h1>
      </section>

      <section className="slider-section">
        <Container>
          <Row>
            <Col sm={12}>
              <h2 className="section-heading">Students Results</h2>
              <Slider params={{ onpage: "home" }} />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="posuere-section">
        <Container>
          <Row>
            <Col sm={12}>
              <div
                dangerouslySetInnerHTML={{
                  __html: pageData,
                }}
              ></div>
            </Col>
          </Row>
        </Container>
      </section>

      <GoogleReview search="SAT" location="Jackson Heights" />

      <section className="pricing-section">
        {/* Prep options */}
        <Courses />
        <Container>
        <div className="refund-policy">
          <Row>
            <Col sm={12}>
              <div
                dangerouslySetInnerHTML={{
                  __html: pageDatarefund,
                }}
              ></div>
            </Col>
          </Row>
          </div>
        </Container>
      </section>

      <section className="green-search">
        <Container>
          <Row>
            <Col sm={12}>
              <div className=" green-container">
                <form className="search-boxin">
                  <div className="input-group">
                    <div style={{ width: "100%" }}>
                      <ReactSearchAutocomplete
                        items={subCategoryList.data}
                        fuseOptions={{
                          useExtendedSearch: true,
                          keys: ["title"],
                          includeScore: true,
                          includeMatches: true,
                        }}
                        showIcon={false}
                        placeholder="Enter test name"
                        resultStringKeyName="title"
                        onSelect={handleOnSelect}
                      />
                      <input
                        type="button"
                        className="search-here btn"
                        value="Find Your Prep"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Home;
