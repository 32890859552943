import React, { useCallback } from "react";
import { Form, Col, Button, Row, Container } from "react-bootstrap";
import { Form as FinalForm, Field } from "react-final-form";
import { useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import {
  composeValidators,
  required,
  isValidEmail
} from "../../common/validationSchema";
import { loginUserAction } from "../../redux/action/Auth";
import "./login.scss";

const LoginPage = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = props.location.state ? props.location.state?.location : "/";
  let redirectUrl;
  const handleLogin = useCallback(
    async values => {
      if (location !== "/cart") {
        redirectUrl = "/user-home";
      } else {
        redirectUrl = "/checkout/payment";
      }
      dispatch(loginUserAction({ values, history: history, redirectUrl }));
    },
    [history, dispatch]
  );

  return (
    <>
      <section className="login-outer">
        <Container>
          <FinalForm
            initialValues={{ email: "", password: "" }}
            onSubmit={handleLogin}
            render={({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <h2>Bobby Tariq User Login</h2>
                <Row>
                  <Col md={12}>
                    <Form.Group controlId="formBasicEmail">
                      <Field
                        name="email"
                        type="email"
                        validate={composeValidators(required, isValidEmail)}
                      >
                        {({ input, meta }) => (
                          <div>
                            <Form.Control {...input} placeholder="Email" />
                            {meta.error && meta.touched && (
                              <span className="error">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                      <Field
                        name="password"
                        type="password"
                        validate={required}
                      >
                        {({ input, meta }) => (
                          <div>
                            <Form.Control {...input} placeholder="Password" />
                            {meta.error && meta.touched && (
                              <span className="error">{meta.error}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    </Form.Group>
                    <Button
                      variant="defult"
                      className="btn-login"
                      type="submit"
                      disabled={submitting}
                    >
                      Login
                    </Button>
                    <div className="text-center w-100">
                      <Link to="forgot-password">Forgot your Password</Link>
                    </div>
                  </Col>
                </Row>

                {/* </div> */}
                {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
              </form>
            )}
          />
        </Container>
      </section>
    </>
  );
};
export default LoginPage;
