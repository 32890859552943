import StarRatings from "react-star-ratings";

const StarRating = props => {
  const { rating, dimension } = props;
  return (
    <StarRatings
      // rating={parseFloat(rating)}
      rating={5}
      starRatedColor="#f5c71a"
      numberOfStars={5}
      name="rating"
      starDimension={dimension}
      starSpacing="0px"
    />
  );
};

export default StarRating;
