import React, { useEffect, useState, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { Col, Row, Container } from "react-bootstrap";
import Slider from "../../components/slider/slider";
import GoogleReview from "../../components/google-review/google-review";
import TabLayout from "../../components/service-tabs/tab";
import SchedulesTab from "../../components/schedules-tab/schedules-tab";
import FaqAccordion from "../../components/faq-accordion/faq-accordion";
import SchedulesModal from "../../components/schedules-modal/schedules-modal";
import { useSelector, useDispatch } from "react-redux";
import { getService, getFAQ } from "../../redux/action/Service";
import "./service.scss";
import SEO from "../../components/seo"; 
import { useMediaPredicate } from "react-media-hook";
import { useLocation } from "react-router-dom";

const Service = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [scroll, setScroll] = useState(false);

  const lg = useMediaPredicate("(min-width: 1024px)");
  const { service, faqList } = useSelector((state) => {
    return state.service;
  });

  useEffect(() => {
    dispatch(getService(params.slug));
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 500);
    });
  }, [params.slug, dispatch]);

  useEffect(() => {
    if (service?._id) {
      dispatch(getFAQ(service._id));
    }
  }, [service, dispatch]);

  const handleScroll = useCallback((id) => () => {
    const top = document.getElementById(id)?.offsetTop || 0;
    window.scrollTo({ top, left: 0, behavior: "smooth" });
  });

  useEffect(() => {
  const handleScroll = () => {
    const triggerSection = document.querySelector('#footer');
    const animateElement = document.querySelector('#small-nav');
    const triggerPosition = triggerSection?.getBoundingClientRect().top;

    const threshold = lg ? 0.8 : 0.3;  

    if (triggerPosition <= window.innerHeight * threshold) {
      animateElement.classList.add('active-small-nav');
    } else {
      animateElement.classList.remove('active-small-nav');
    }
  };

  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, [lg]);
  
  
  return (
    <>
      <SEO
        title={service?.meta_title}
        description={service?.meta_description}
        keywords={service?.meta_keywords}
      />
      <section className="main-banner service">
        <Container>
          <Row className="align-items-center">
            <Col md={5}>
              <h2 className="mb-1">{service?.title}</h2>
              <h4 className="mb-3">{service?.line1_description}</h4>
              <p>{service?.line2_description}</p>
            </Col>
            <Col md={1}></Col>
            <Col md={6}>
              <div className="prep-outer text-center">
                <div className="boxes" onClick={handleScroll("cdesc")}>
                  <h5>
                    <button>Course Descriptions</button>
                  </h5>
                </div>
                <div className="boxes" onClick={handleScroll("std")}>
                  <h5>
                    <button>Student Results</button>
                    {/* <a href="#std">Student Results</a> */}
                  </h5>
                </div>
                <div className="boxes" onClick={handleScroll("greview")}>
                  <h5>
                    <button>Google Reviews</button>
                    {/* <a href="#greview">Google Reviews</a> */}
                  </h5>
                </div>
                <div className="boxes" onClick={handleScroll("spricing")}>
                  <h5>
                    <button>Schedules & Pricing</button>
                    {/* <a href="#spricing">Schedules & Pricing</a> */}
                  </h5>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className={scroll ? "stickt-box" : "hide-box"} id="small-nav">
        <Container>
          <Row>
            <Col sm={12}>
              <div className="small-nav-outer">
                <div className="site-logo">
                  <Link to="/">
                    <img
                      src="/images/logo.svg"
                      className="img-fluid"
                      alt="LOGO"
                    />
                  </Link>
                </div>
                <div className="three-menu">
                  <button onClick={handleScroll("cdesc")}>
                    Course Descriptions
                  </button>
                  <button onClick={handleScroll("std")}>Student Results</button>
                  <button onClick={handleScroll("greview")}>
                    Google Reviews
                  </button>
                  <button onClick={handleScroll("spricing")}>
                    Schedules & Pricing
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {service?.tabs && service.tabs.length > 0 ? (
        <div id="cdesc">
          <TabLayout tabs={service.tabs} />
        </div>
      ) : null}
      <section id="std" className="slider-section">
        <Container>
          <Row>
            <Col md={12}>
              <h2 className="section-heading">Students Results</h2>
              {service?._id ? (
                <Slider params={{ subcategoryId: service._id }} />
              ) : null}
            </Col>
          </Row>
        </Container>
      </section>
      <div id="greview">
        {service && (
          <GoogleReview
            category={service?.category_title}
            location="Jackson Heights"
          />
        )}
      </div>
      {service?.title ? (
        <div id="spricing">
          <SchedulesTab
            id={Object.keys(service).length ? service._id : ""}
            title={service?.title}
          />
        </div>
      ) : null}
      {faqList.length > 0 ? <FaqAccordion list={faqList} /> : null}
      <SchedulesModal />
      <section className="green-search">
        <Container>
          <Row>
            <Col sm={12}>
              <div className=" green-container">
                <Link to="/contact-us" className="contact-us-btn">
                  Contact Us
                </Link>
                {/* <h1 className="watermark-text">Contact Us</h1> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Service;
