export const getMonths = () => {
  return {
    monthArray: [
      { name: "January", value: "01" },
      { name: "February", value: "02" },
      { name: "March", value: "03" },
      { name: "April", value: "04" },
      { name: "May", value: "05" },
      { name: "June", value: "06" },
      { name: "July", value: "07" },
      { name: "August", value: "08" },
      { name: "September", value: "09" },
      { name: "October", value: "10" },
      { name: "November", value: "11" },
      { name: "December", value: "12" }
    ]
  };
};

export const getYears = () => {
  const currentYear = new Date().getFullYear();
  const range = (start, stop, step) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    );

  return range(currentYear, currentYear + 14, 1);
};

export const currencyFormat = num => {
  if (num) {
    if (num % 1 !== 0) {
      return (
        "$" +
        parseFloat(num)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      );
    } else {
      return (
        "$" +
        parseInt(num)
          .toFixed(0)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      );
    }
  } else {
    return "$ 0.00";
  }
};
