import {
  COURSES_LIST_REQUEST,
  COURSES_REQUEST,
  SHOWMODAL_REQUEST,
  ADDCART_REQUEST
} from "../constants";

export const getCoursesList = param => {
  return {
    type: COURSES_LIST_REQUEST,
    payload: { param }
  };
};

export const getCoursesBySubCategoryId = payload => {
  return {
    type: COURSES_REQUEST,
    payload
  };
};

export const showModalById = id => {
  return {
    type: SHOWMODAL_REQUEST,
    payload: { id }
  };
};

export const addCourseCountToCart = count => {
  return {
    type: ADDCART_REQUEST,
    payload: { count }
  };
};
