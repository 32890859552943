import axios from "axios";
import authHeader from "./auth-header";
import { toast } from "react-toastify";
import { baseUrl } from "../../ennviroment";


export const resultListAction = (params) => {
  try {
    return axios.get(
      `${baseUrl}api/v1/result/get-result?${new URLSearchParams(
        params
      ).toString()}`
    );
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.message);
  }
};

export const categoryListAction = () => {
  try {
    return axios.get(`${baseUrl}api/v1/category/get-category`);
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.message);
  }
};

export const courseListAction = (params) => {
  try {
    return axios.get(
      `${baseUrl}api/v1/course/get-course?${new URLSearchParams(
        params
      ).toString()}`
    );
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.message);
  }
};

export const courseByIdAction = (params) => {
  try {
    return axios.get(
      `${baseUrl}api/v1/course/get-course?${new URLSearchParams(
        params
      ).toString()}`
    );
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.message);
  }
};

export const subCategoriesListAction = () => {
  try {
    return axios.get(`${baseUrl}api/v1/category/get-subcategory`);
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.message);
  }
};

export const serviceAction = (serviceId) => {
  try {
    return axios.get(
      `${baseUrl}api/v1/category/get-subcategory?slug=` + serviceId
    );
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.message);
  }
};

export const orderByUserIdAction = (userId) => {
  try {
    return axios.get(`${baseUrl}api/v1/order/get-orders?userId=` + userId, {
      headers: authHeader(),
    });
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.message);
  }
};

export const detailsByUserIdAction = (userId) => {
  try {
    return axios.get(`${baseUrl}api/v1/user/get-all-user?id=` + userId, {
      headers: authHeader(),
    });
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.message);
  }
};

export const blogListAction = (params) => {
  try {
    return axios
      .get(
        `${baseUrl}api/v1/blogs/get-blog?${new URLSearchParams(
          params
        ).toString()}`
      )
      .then((response) => ({ response }));
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.message);
  }
};

export const blogAction = (id) => {
  try {
    return axios
      .get(`${baseUrl}api/v1/blogs/get-blog?slug=${id}`)
      .then((response) => ({ response }));
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.message);
  }
};

export const cartAction = (userId) => {
  try {
    return axios.get(`${baseUrl}api/v1/cart/get-cart?userId=` + userId, {
      headers: authHeader(),
    });
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.message);
  }
};

export const getPaymentMethod = (userId) => {
  try {
    return axios
      .get(`${baseUrl}api/v1/card/get-card?userId=` + userId, {
        headers: authHeader(),
      })
      .then((response) => {
        return response.data;
      });
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.message);
  }
};

export const updateUserInfoAction = (values) => {
  const { subscriptions, student_email, ...rest } = values;
  try {
    return axios
      .post(`${baseUrl}api/v1/user/update-user`, rest, {
        headers: authHeader(),
      })
      .then((response) => {
        return response;
      });
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.message);
  }
};

export const shiftCartAction = (cart) => {
  try {
    return axios.post(`${baseUrl}api/v1/cart/shift-cart`, cart, {
      headers: authHeader(),
    });
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.message);
  }
};

export const faqAction = (id) => {
  try {
    return axios.get(`${baseUrl}api/v1/faq/get-FAQ?subcategoryId=` + id);
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.message);
  }
};

export const reviewAction = (params) => {
  try {
    return axios.get(
      `${baseUrl}api/v1/reviews/get-review?${new URLSearchParams(
        params
      ).toString()}`
    );
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.message);
  }
};
//localhost:5036/api/v1/reviews/get-review?page=1&count=20

export const registerUserAction = async ({ values, history,form }) => {

  try {
    const {
      data: { message, statusCode },
    } = await axios.post(`${baseUrl}api/v1/checkout`, values);
    console.log({message, statusCode });
    if (statusCode === 200) {
      toast.success(message);
      form.reset();
    }
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.message);
  }
};

export const loginUserAction = (user) => {
  try {
    return axios
      .post(`${baseUrl}api/v1/user/signin-user`, user)
      .then((response) => {
        if (response.data.data.loginToken) {
          localStorage.setItem("user", JSON.stringify(response.data.data));
        }
        return response.data;
      });
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.message);
  }
};

export const logoutAction = () => {
  localStorage.removeItem("user");
};
// Slugs API //
export const slugFirstAction = async () => {
  try {
    return axios
      .get(`${baseUrl}api/v1/page/get-page?slug=experienced-teachers`)
      .then((response) => {
        if (response.data.statusCode === 200) {
          return response.data.data;
        }
      });
  } catch (err) {
    console.log(err);
    toast.error(err.response.data.message);
  }
};
