import {
  COURSES_LIST_REQUEST,
  COURSES_LIST_SUCCESS,
  COURSES_REQUEST,
  COURSES_SUCCESS,
  SHOWMODAL_REQUEST,
  ADDCART_REQUEST
} from "../constants";
const initialState = {
  isLoading: false,
  courseList: [],
  modalCourseId: null,
  count: 0
};
const CoursesReducer = (
  state = initialState,
  { type = null, payload = null } = {}
) => {
  switch (type) {
    case COURSES_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        param: payload.param
      };
    case COURSES_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        courseList: payload && payload
      };
    case COURSES_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case SHOWMODAL_REQUEST:
      return {
        ...state,
        isLoading: false,
        modalCourseId: payload.id
      };
    case COURSES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        courseList: payload && payload
      };
    case ADDCART_REQUEST:
      return {
        ...state,
        isLoading: false,
        count: payload.count + 1
      };
    default:
      return state;
  }
};
export default CoursesReducer;
