import { fork, all, takeLatest, call, put } from "redux-saga/effects";
import { SLUG_REQUEST, SLUG_SUCCESS } from "../constants";
import { slugFirstAction } from "../services/api";
function* watchSlug() {
  yield takeLatest(SLUG_REQUEST, getFirstSlugFromAPI);
}
function* getFirstSlugFromAPI() {
  const slugFirstBlock = yield call(slugFirstAction);

  if (slugFirstBlock.data.statusCode === 200) {
    yield put({
      type: SLUG_SUCCESS,
      payload: slugFirstBlock.data.data
    });
  }
}

export default function* slugSaga() {
  yield all([fork(watchSlug)]);
}
