export const paymentInitials = {
  userId: "",
  paymentType: "",
  cc: "",
  cvv: "",
  cardfirstName: "",
  cardlastName: "",
  expire: "",
  expireMonth: "01",
  expireYear: "2021",
  amount: "",
  cardzip: "",
  date: "",
  unit: "days",
  length: "10",
  instalments: "",

  order: {
    order_amount: 0,
    discount: 0,
    order_total: 0
  },
  orderDetails: {
    courses: [
      {
        courseId: "",
        categoryId: "",
        subCategoryId: "",
        price: 0,
        disc: 0,
        name: ""
      }
    ]
  }
};
