import { fork, all, takeLatest, call, put } from 'redux-saga/effects'
import {
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS
} from "../constants";
import {
    categoryListAction
} from "../services/api"
function* watchCategoryList() {
  yield takeLatest(CATEGORY_LIST_REQUEST, getCategoryFromAPI)
}
function* getCategoryFromAPI() {
    const categoryList = yield call(categoryListAction)
    if(categoryList.data.statusCode === 200) {
        yield put({
          type: CATEGORY_LIST_SUCCESS,
          payload: categoryList.data.data
        })
      }
}
export default function* categorySaga() {
    yield all([
      fork(watchCategoryList),
    ])
}
