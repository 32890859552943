import {
    BLOG_LIST_ERROR,
    BLOG_LIST_REQUEST,
    BLOG_LIST_SUCCESS,
    BLOG_REQUEST,
    BLOG_SUCCESS,
    BLOG_ERROR
} from "../constants"
const initialState = {
    isLoading: false,
    blogList: {contacts:[], totalContact:0},
    blog: {contacts:[], totalContact:0}
}
const BlogsReducer = (state = initialState, { type =null, payload=null  }={}) => {
    switch (type) {
        case BLOG_LIST_REQUEST: 
            return {
                ...state,
                isLoading: true,
                blogList: {contacts:[], totalContact:0}
            }
        case BLOG_LIST_SUCCESS: 
            return {
                ...state,
                isLoading: false,
                blogList: payload
            }
        case BLOG_LIST_ERROR: 
            return {
                ...state,
                isLoading: false
            }
        case BLOG_REQUEST: 
            return {
                ...state,
                isLoading: true,
                blog: {contacts:[], totalContact:0}
            }
        case BLOG_SUCCESS: 
            return {
                ...state,
                isLoading: false,
                blog: payload
            }
        case BLOG_ERROR: 
            return {
                ...state,
                isLoading: false
            }
        default: 
            return state;
    }
}
export default BlogsReducer;