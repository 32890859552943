import {
  ORDER_REQUEST,
  USER_DETAILS_REQUEST,
  USER_PAYMENT_REQUEST,
  USER_DETAILS_UPDATE_REQUEST
} from "../constants";

export const getOrdersByUserId = id => {
  return {
    type: ORDER_REQUEST,
    payload: { id }
  };
};

export const getUserDetailsById = id => {
  return {
    type: USER_DETAILS_REQUEST,
    payload: { id }
  };
};

export const getPaymentMethodById = id => {
  return {
    type: USER_PAYMENT_REQUEST,
    payload: { id }
  };
};

export const updatePersonalInfo = payload => {
  return {
    type: USER_DETAILS_UPDATE_REQUEST,
    payload: payload
  };
};
