import { createStore, applyMiddleware } from "redux";
import rootReducer from "./reducer";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { composeWithDevTools } from "redux-devtools-extension";

const persistConfig = {
  key: "course-abc",
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();
function configureStore() {
  let store = createStore(
    persistedReducer,
    composeWithDevTools(
      applyMiddleware(sagaMiddleware)
      /*applyMiddleware(checkTokenExpirationMiddleware),*/
      /*applyMiddleware(thunk)*/
    )
  );
  return store;
}
const store = configureStore();
rootSaga(sagaMiddleware);
export default store;
