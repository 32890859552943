import { fork, all, takeLatest, call, put } from "redux-saga/effects";
import {
  BLOG_LIST_REQUEST,
  BLOG_LIST_SUCCESS,
  BLOG_LIST_ERROR,
  BLOG_REQUEST,
  BLOG_SUCCESS,
  BLOG_ERROR
} from "../constants";
import { blogListAction, blogAction } from "../services/api";
function* watchBlogList() {
  yield takeLatest(BLOG_LIST_REQUEST, getBlogsFromAPI);
}
function* watchBlog() {
  yield takeLatest(BLOG_REQUEST, getBlogFromAPI);
}
function* getBlogsFromAPI(action) {
  const { response, error } = yield call(blogListAction, action.payload);
  if (response) {
    yield put({
      type: BLOG_LIST_SUCCESS,
      payload: response.data.data
    });
  } else {
    yield put({
      type: BLOG_LIST_ERROR,
      payload: error
    });
  }
}
function* getBlogFromAPI(action) {
  const { response, error } = yield call(blogAction, action.params.id);
  if (response.data.statusCode === 200) {
    if (response.data.data.contacts.length < 1) {
      action.params.history.push("/not-found");
    }
    yield put({
      type: BLOG_SUCCESS,
      payload: response.data.data
    });
  } else {
    yield put({
      type: BLOG_ERROR,
      payload: error
    });
  }
}
export default function* blogsSaga() {
  yield all([fork(watchBlogList), fork(watchBlog)]);
}
