import React, { useEffect, useState } from "react";
import { Tab, Col, Nav, Row, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import "./tab.scss";

const TabLayout = (props) => {
  const tabs = props.tabs;
  const [defaultTab, setDefaultTab] = useState("first_0");

  const location = useLocation();

  useEffect(() => {
    setDefaultTab("first_0");
  }, [location]);

  const images = [
    "book-icon.svg",
    "language-icon.svg",
    "math-icon.svg",
    "rating-icon.svg",
    "test-icon.svg",
    "plan-icon.svg",
  ];
  console.log(tabs);

  return (
    <>
      <section className="service-tabs">
        <Tab.Container
          id="Service-tab"
          activeKey={defaultTab}
          onSelect={(e) => setDefaultTab(e)}
        >
          <Container>
            <Row>
              <Col sm={12}>
                <Nav className="flex-row justify-content-between">
                  {tabs.map((val, index) => (
                    <Nav.Item key={index}>
                      <Nav.Link eventKey={`first_${index}`}>
                        <img src={`/images/${images[index]}`} alt="" />
                        {val.title}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
              <Col sm={12}>
                <Tab.Content>
                  {tabs.map((val, index) => (
                    <Tab.Pane eventKey={`first_${index}`} key={index}>
                      <Row>
                        <Col sm={6}>
                          <div className="service-discrption">
                            <h4>{val.title}</h4>
                            <small>{val.subtitle}</small>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: val.description,
                              }}
                            ></p>

                            <a href="#">Enroll Now</a>
                          </div>
                        </Col>
                        <Col sm={6}>
                          {val.image && (
                            <div className="tab-images">
                              <img
                                src={val.image?.replace(
                                  "dax3hbhvk7d4y",
                                  "d16k95ykg4bhzr"
                                )}
                                className="img-fluid"
                                alt="Reading-banner"
                              />
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
            </Row>
          </Container>
        </Tab.Container>
      </section>
    </>
  );
};
export default TabLayout;
