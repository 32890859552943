import { fork, all, takeLatest, call, put, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  REGISTER_USER,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  EMPTY_CART,
  GET_CART_REQUEST,
  SHIFT_CART
} from "../constants";
import { registerUserAction, loginUserAction } from "../services/api";

function* watchUserAuthentication() {
  yield takeLatest(REGISTER_USER, registerUserAPI);
  yield takeLatest(LOGIN_USER, loginUserAPI);
}

function* registerUserAPI(payload) {
  try {
    const response = yield call(registerUserAction, payload.payload);
    if (response.statusCode === 200) {
      toast.success(response.message);
      yield put({ type: REGISTER_SUCCESS, payload: response.data.UserData });
      yield put({ type: EMPTY_CART });
      payload.payload.history.push("/user-home");
    } else {
      toast.error(response.data.errors[0].msg);
      yield put({ type: REGISTER_FAIL });
    }
  } catch (error) {
    //yield put({ type: REGISTER_FAIL, error });
  }
}

const getCartState = state => state.cart;

export function* loginUserAPI(payload) {
  try {
    const { items } = yield select(getCartState);
    const response = yield call(loginUserAction, payload.payload.values);
    if (response.statusCode === 200) {
      yield put({ type: LOGIN_SUCCESS, payload: response.data });

      if (items.length) {
        yield put({
          type: SHIFT_CART,
          payload: { userId: response.data._id, cart: items }
        });
      } else {
        yield put({ type: GET_CART_REQUEST, payload: response.data._id });
      }

      payload.payload.history.push(payload.payload.redirectUrl);
    }
  } catch (error) {
    yield put({ type: LOGIN_FAIL, error });
  }
}

export default function* authSaga() {
  yield all([fork(watchUserAuthentication)]);
}
