import React, { useState } from "react";
import { Form, Col, Button, Row, Container } from "react-bootstrap";
import "./forgot-password.scss";
import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl } from "../../ennviroment";

const ForgotPassword = () => {
  const [email, setemail] = useState("");
  const [sending, setSending] = useState(false);
  const handleSubmit = e => {
    e.preventDefault();
    setSending(true);
    axios
      .post(`${baseUrl}api/v1/user/forgotpassword`, {
        email: email
      })
      .then(res => {
        setSending(false);
        toast.success("Email sent successful");
      })
      .catch(err => {
        setSending(false);
        toast.error("Please try again later!");
      });
  };
  return (
    <>
      <section className="login-outer">
        <Container className="forgot-info">
          <Row>
            <Col md={12}>
              <Form>
                <h2 className="mb-4">Create or reset password</h2>
                <p className="mb-4">
                  Provide your email address below ad we will send instruction
                  on how to set your password
                </p>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control
                    type="email"
                    onChange={e => {
                      setemail(e.target.value);
                    }}
                  />
                  <Form.Label>Email Address</Form.Label>
                </Form.Group>
                <Button
                  onClick={e => {
                    handleSubmit(e);
                  }}
                  type="submit"
                  className="btn-primery"
                  disabled={sending}
                >
                  Submit
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default ForgotPassword;
