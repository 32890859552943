import {
    CATEGORY_LIST_REQUEST,
    CATEGORY_LIST_SUCCESS
} from "../constants"
const initialState = {
    isLoading: false,
    categoryList: []
}
const CategoriesReducer = (state = initialState, { type =null, payload=null  }={}) => {
    switch (type) {
        case CATEGORY_LIST_REQUEST: 
            return {
                ...state,
                isLoading: true,
                categoryList: []
            }
        case CATEGORY_LIST_SUCCESS: 
            return {
                ...state,
                isLoading: false,
                categoryList: payload
            }
        default: 
            return state;
    }
}
export default CategoriesReducer;