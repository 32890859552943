import React, { useState, useEffect, useRef } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "./footer.scss";
import axios from "axios";
import clsx from "clsx";
import { extractPhoneNumber, getRanges } from "../../utils/helpers";
import { baseUrl } from "../../ennviroment";

const SLUGS = {
  Jamaica: "/jamaica",
  "Jackson Heights": "/jackson-heights",
};

const Footer = () => {
  const [close, setClose] = useState(false);
  const [talkToSales, setTalkToSales] = useState([]);
  const [locations, setLocations] = useState([]);
  const popoverRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    setClose(false);
  }, [location]);

  useEffect(() => {
    axios
      .get(`${baseUrl}api/v1/talktosales/get-locationhours`)
      .then((res) => {
        setTalkToSales(res?.data?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${baseUrl}api/v1/location/get-locationhours`)
      .then((res) => {
        setLocations(
          res?.data?.data?.data?.map((node) => {
            return {
              ...node,
              slug: SLUGS[node.location],
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const year = new Date().getFullYear();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        close &&
        popoverRef.current &&
        !popoverRef.current.contains(e.target)
      ) {
        setClose(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside, {
      passive: true,
    });
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [close]);

  return (
    <>
      <footer id="footer">
        <div className="talk-to-sales-button" onClick={() => setClose(true)}>
          <div className="icons">
            <img
              src="https://www.bobbytariq.com/images/call-green.svg"
              alt="icon"
            />
            <div className="line"></div>
            <img
              src="https://www.bobbytariq.com/images/email-green.svg"
              alt="icon"
            />
          </div>
          <div className="title">Talk to sales</div>
        </div>
        <div
          ref={popoverRef}
          className={clsx(
            "talk-to-sales-container",
            close
              ? "talk-to-sales-container-opened"
              : "talk-to-sales-container-closed "
          )}
        >
          <div className="talk-to-sales-container-inner">
            <div className="close" onClick={() => setClose(false)}>
              {" "}
              X
            </div>
            <div className="title">How can we help?</div>

            {talkToSales.map(
              ({ location, status, phone, day_details }, index) => {
                if (status === "Active") {
                  const ranges = getRanges(day_details);
                  const tel = extractPhoneNumber(phone);

                  return (
                    <>
                      <div className="talk-item-wrapper-popover">
                        {location && (
                          <div className="talk-item-footer">
                            <span className="location-title-popover">
                              {location}
                            </span>
                          </div>
                        )}
                        {phone && (
                          <div className="talk-item-footer">
                            <a
                              href={`tel:${tel}`}
                              className="phone-anchor-item"
                            >
                              &nbsp;{phone}
                            </a>
                          </div>
                        )}

                        {ranges?.length > 0 &&
                          ranges?.map((node, i) => {
                            return (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: node,
                                }}
                                key={i}
                                className="talk-item-footer"
                              ></div>
                            );
                          })}
                      </div>
                      {index <= talkToSales?.length - 2 && (
                        <hr className="hr-talk-item" />
                      )}
                    </>
                  );
                }
              }
            )}
          </div>
        </div>
        <Container>
          <Row className="mb-4">
            <Col md className="mt-4">
              <h3>SAT Prep</h3>
              <ul>
                <li>
                  <Link
                    to={{
                      pathname: `/sat-prep`,
                      state: { id: "60c1cfb81fb194696be19fd1" },
                    }}
                    className="nav-link"
                  >
                    SAT Prep
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: `/sat-private-tutoring`,
                      state: { id: "60c2d1d4c3fc2b7347068a5c" },
                    }}
                    className="nav-link"
                  >
                    SAT Private Tutoring
                  </Link>
                </li>
              </ul>
            </Col>
            <Col md className="mt-4">
              <h3>SHSAT Prep</h3>
              <ul>
                <li>
                  <Link
                    to={{
                      pathname: `/shsat-prep`,
                      state: { id: "60c1d1821fb194696be19fd6" },
                    }}
                    className="nav-link"
                  >
                    SHSAT Prep
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: `/shsat-private-tutoring`,
                      state: { id: "60c2d1eac3fc2b7347068a6a" },
                    }}
                    className="nav-link"
                  >
                    SHSAT Private Tutoring
                  </Link>
                </li>
              </ul>
            </Col>
            <Col md className="mt-4">
              <h3>9th Grade SHSAT Prep</h3>
              <ul>
                <li>
                  <Link
                    to={{
                      pathname: `/9th-grade-shsat-prep`,
                      state: { id: "60c1f8ec64aec620037821d8" },
                    }}
                    className="nav-link"
                  >
                    9th Grade SHSAT Prep
                  </Link>
                </li>
                <li>
                  <Link
                    to={{
                      pathname: `/9th-grade-shsat-private-tutoring`,
                      state: { id: "60c2d200c3fc2b7347068a71" },
                    }}
                    className="nav-link"
                  >
                    9th Grade SHSAT Private Tutoring
                  </Link>
                </li>
              </ul>
            </Col>
            <Col md className="mt-4">
              <h3>About Us</h3>
              <ul>
                {locations?.map(({ location, slug }) => {
                  return (
                    <li key={slug}>
                      <Link to={slug} className="nav-link">
                        {location}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </Col>
            <Col md className="mt-4">
              <h3>Contact Us</h3>
              <Row>
                <ul>
                  {talkToSales.map(
                    ({ location, status, phone, day_details }, index) => {
                      if (status === "Active") {
                        const ranges = getRanges(day_details);
                        const tel = extractPhoneNumber(phone);

                        return (
                          <li>
                            <p className="location-title">{location}</p>
                            <a href={`tel:${tel}`} className="nav-link-2nd">
                              &nbsp;{phone?.replace("Call: ", "")}
                            </a>
                            <a
                              href="mailto:service@bobbytariq.com"
                              className="nav-link-2nd"
                            >
                              service@bobbytariq.com
                            </a>

                            {index <= talkToSales?.length - 2 && (
                              <hr className="hr-talk-item" />
                            )}
                          </li>
                        );
                      }
                    }
                  )}
                </ul>
              </Row>
            </Col>
          </Row>
        </Container>
        <hr />
        <div className="bottom-footer">
          <div className="container">
            <Row>
              <Col md={3}>
                <div className="site-logo">
                  <Link to="/">
                    <img
                      src="/images/footer-logo.png"
                      className="img-fluid"
                      alt="LOGO"
                    />
                  </Link>
                </div>
              </Col>
              <Col md={6}>
                <div className="copyright text-center">
                  <p>
                    Copyright Notice |
                    <Link to="/terms-and-conditions" className="nav-link-terms">
                      Terms & Conditions
                    </Link>
                  </p>
                  <p>
                    © {year} Bobby-Tariq Tutoring Center. All rights reserved.
                  </p>
                </div>
              </Col>
              <Col md={3}>
                <div className="social-icon">
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/BobbyTariqTutoringCenter/"
                        target="_blank"
                        rel="noreferrer"
                        className="nav-link"
                      >
                        <img
                          src="/images/facebook-icon.svg"
                          className="img-fluid"
                          alt="facebook"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/realbobbytariq?lang=en"
                        target="_blank"
                        rel="noreferrer"
                        className="nav-link"
                      >
                        <img
                          src="/images/twitter-icon.svg"
                          className="img-fluid"
                          alt="twitter"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/bobby_tariq/?hl=en"
                        target="_blank"
                        rel="noreferrer"
                        className="nav-link"
                      >
                        <img
                          src="/images/instagram-icon.svg"
                          className="img-fluid"
                          alt="instagram"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/channel/UCPGlut9qBdo162SK8vwin6g"
                        target="_blank"
                        rel="noreferrer"
                        className="nav-link"
                      >
                        <img
                          src="/images/youtube-icon.svg"
                          className="img-fluid"
                          alt="youtube"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
