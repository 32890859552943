import { REGISTER_USER, LOGIN_USER, LOGOUT } from "../constants";

export const registerUserAction = user => {
  console.log(user);
  return {
    type: REGISTER_USER,
    payload: user
  };
};

export const loginUserAction = user => {
  return {
    type: LOGIN_USER,
    payload: user
  };
};

export const logout = () => {
  localStorage.removeItem("user");
  return {
    type: LOGOUT
  };
};
