import {
  SUBCATEGORY_LIST_REQUEST,
  SUBCATEGORY_LIST_SUCCESS
} from "../constants";
const initialState = {
  isLoading: false,
  subCategoryList: []
};
const SubCategoriesReducer = (
  state = initialState,
  { type = null, payload = null } = {}
) => {
  switch (type) {
    case SUBCATEGORY_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        subCategoryList: []
      };
    case SUBCATEGORY_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        subCategoryList: payload
      };
    default:
      return state;
  }
};
export default SubCategoriesReducer;
