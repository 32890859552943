import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  GET_CART_REQUEST,
  SHIFT_CART,
  CHANGE_PAYMENT_TYPE
} from "../constants";
export const removeFromCart = id => {
  return {
    type: REMOVE_FROM_CART,
    payload: { id }
  };
};

export const addToCart = course => {
  return {
    type: ADD_TO_CART,
    payload: course
  };
};

export const changePaymentType = type => {
  return {
    type: CHANGE_PAYMENT_TYPE,
    payload: type
  };
};

export const getCart = userId => {
  return {
    type: GET_CART_REQUEST,
    payload: { userId }
  };
};

export const shiftCart = cart => {
  return {
    type: SHIFT_CART,
    payload: cart
  };
};
