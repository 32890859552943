import React, { useState, useEffect } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getCoursesList } from "../../../redux/action/Courses";
import { useSelector, useDispatch } from "react-redux";
const Courses = () => {
  const dispatch = useDispatch();
  const [currencyNumberFormat, setCurrencyNumberFormat] = useState(
    new Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    })
  );
  const { isLoading, courseList } = useSelector(state => {
    return state.courses;
  });
  useEffect(() => {
    dispatch(getCoursesList({ place: "home" }));
  }, []);
  if (isLoading) {
    return <div>Loading....</div>;
  }
  return (
    <Container>
      <Row>
        <Col sm={12}>
          <div className=" bg-sky w-100">
            <h2 className="section-heading mt-5 mb-3">Prep Options</h2>
            <Row>
              {courseList &&
                courseList.map((_course, index) => {
                  if (index >= 4) return null;
                  return (
                    <Col key={index} md={6} lg={3}>
                      <div className="price-box">
                        <h5>
                          {_course.subcategory_name}
                          <br />
                          {/* ( Summer ){_course.tag} */}
                        </h5>
                        <h3>${currencyNumberFormat.format(_course.total)}</h3>
                        <p>
                          {_course.service_type} & Live Online
                          <br /> {_course.noof_classes} Classes
                        </p>
                        <Link
                          to={{
                            pathname: `/${_course.subcategory_slug}`,
                            state: { id: _course.subcategoryId }
                          }}
                        >
                          Learn More
                        </Link>
                        {/* <Link to={`/service/${_course.subcategoryId}`}></Link> */}
                      </div>
                    </Col>
                  );
                })}
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
export default Courses;
