import { fork, all, takeLatest, call, put } from "redux-saga/effects";
import {
  ORDER_REQUEST,
  ORDER_SUCCESS,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_PAYMENT_REQUEST,
  USER_PAYMENT_SUCCESS,
  USER_DETAILS_UPDATE_REQUEST,
  USER_DETAILS_UPDATE_SUCCESS,
  LOGOUT
} from "../constants";
import {
  orderByUserIdAction,
  detailsByUserIdAction,
  getPaymentMethod,
  updateUserInfoAction
} from "../services/api";
function* watchUser() {
  yield takeLatest(ORDER_REQUEST, getOrderByUserId);
  yield takeLatest(USER_DETAILS_REQUEST, getUserDetailsByUserId);
  yield takeLatest(USER_PAYMENT_REQUEST, getUserPaymentByUserId);
  yield takeLatest(USER_DETAILS_UPDATE_REQUEST, updateUserInfoByUserId);
}

function* getOrderByUserId(action) {
  const orders = yield call(orderByUserIdAction, action.payload.id);
  if (orders.data.statusCode === 200) {
    yield put({
      type: ORDER_SUCCESS,
      payload: orders.data.data
    });
  }
}

function* getUserDetailsByUserId(action) {
  const details = yield call(detailsByUserIdAction, action.payload.id);
  if (details.data.statusCode === 200) {
    yield put({
      type: USER_DETAILS_SUCCESS,
      payload: details.data.data
    });
  }
}

function* getUserPaymentByUserId(action) {
  const payment = yield call(getPaymentMethod, action.payload.id);
  if (payment.statusCode === 200) {
    yield put({
      type: USER_PAYMENT_SUCCESS,
      payload: payment.data
    });
  }
}

function* updateUserInfoByUserId(action) {
  const response = yield call(updateUserInfoAction, action.payload.values);
  if (response.data.statusCode === 200) {
    if (action.payload.history.location.pathname === "/security") {
      action.payload.history.push("/");
      yield put({
        type: LOGOUT
      });
    } else {
      yield put({
        type: USER_DETAILS_UPDATE_SUCCESS,
        payload: response.data
      });
    }
  }
}

export default function* userSaga() {
  yield all([fork(watchUser)]);
}
