import { SLUG_REQUEST, SLUG_SUCCESS } from "../constants";
const initialState = {
  isLoading: false,
  slug: {}
};
const SlugReducer = (
  state = initialState,
  { type = null, payload = null } = {}
) => {
  switch (type) {
    case SLUG_REQUEST:
      return {
        ...state,
        isLoading: true,
        slug: {}
      };
    case SLUG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        slug: payload && payload.data[0]
      };
    default:
      return state;
  }
};
export default SlugReducer;
