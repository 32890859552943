export const RESULT_LIST_REQUEST = "RESULT_LIST_REQUEST";
export const RESULT_LIST_SUCCESS = "RESULT_LIST_SUCCESS";
export const CATEGORY_LIST_REQUEST = "CATEGORY_LIST_REQUEST";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const COURSES_LIST_REQUEST = "COURSES_LIST_REQUEST";
export const COURSES_LIST_SUCCESS = "COURSES_LIST_SUCCESS";
export const COURSES_REQUEST = "COURSES_REQUEST";
export const COURSES_SUCCESS = "COURSES_SUCCESS";
export const SUBCATEGORY_LIST_REQUEST = "SUBCATEGORY_LIST_REQUEST";
export const SUBCATEGORY_LIST_SUCCESS = "SUBCATEGORY_LIST_SUCCESS";
export const SLUG_REQUEST = "SLUG_REQUEST";
export const SLUG_SUCCESS = "SLUG_SUCCESS";
export const SERVICE_REQUEST = "SERVICE_REQUEST";
export const SERVICE_SUCCESS = "SERVICE_SUCCESS";
export const FAQ_REQUEST = "FAQ_REQUEST";
export const FAQ_SUCCESS = "FAQ_SUCCESS";
export const REVIEW_REQUEST = "REVIEW_REQUEST";
export const REVIEW_SUCCESS = "REVIEW_SUCCESS";
export const SHOWMODAL_REQUEST = "SHOWMODAL_REQUEST";
export const ADDCART_REQUEST = "ADDCART_REQUEST";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const GET_CART_REQUEST = "GET_CART_REQUEST";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_FAIL = "GET_CART_FAIL";
export const SHIFT_CART = "SHIFT_CART";
export const EMPTY_CART = "EMPTY_CART";
export const BLOG_LIST_REQUEST = "BLOG_LIST_REQUEST";
export const BLOG_LIST_SUCCESS = "BLOG_LIST_SUCCESS";
export const BLOG_LIST_ERROR = "BLOG_LIST_ERROR";
export const BLOG_REQUEST = "BLOG_REQUEST";
export const BLOG_SUCCESS = "BLOG_SUCCESS";
export const BLOG_ERROR = "BLOG_ERROR";

// User Login/Signup //

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

// User Order History //

export const ORDER_REQUEST = "ORDER_REQUEST";
export const ORDER_SUCCESS = "ORDER_SUCCESS";
export const ORDER_FAIL = "ORDER_FAIL";

// User Details //
export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_PAYMENT_REQUEST = "USER_PAYMENT_REQUEST";
export const USER_PAYMENT_SUCCESS = "USER_PAYMENT_SUCCESS";

export const USER_DETAILS_UPDATE_REQUEST = "USER_DETAILS_UPDATE_REQUEST";
export const USER_DETAILS_UPDATE_SUCCESS = "USER_DETAILS_UPDATE_SUCCESS";
export const USER_DETAILS_UPDATE_FAIL = "USER_DETAILS_UPDATE_FAIL";

export const CHANGE_PAYMENT_TYPE = "CHANGE_PAYMENT_TYPE";
