export const getRanges = (dayDetails) => {
  const activeDays = dayDetails.filter((day) => day.status === "Active");
  const dayOrder = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  activeDays.sort((a, b) => dayOrder.indexOf(a.day) - dayOrder.indexOf(b.day));

  const ranges = [];
  let currentRange = null;

  dayOrder.forEach((day) => {
    const dayDetail = activeDays.find((detail) => detail.day === day);
    if (dayDetail) {
      const timeRange = `${dayDetail.start_time} <span class="hyphen-number"></span>${dayDetail.end_time}`;

      if (currentRange && currentRange.timeRange === timeRange) {
        currentRange.days.push(day.slice(0, 3));
      } else {
        if (currentRange) {
          ranges.push(currentRange);
        }
        currentRange = {
          days: [day.slice(0, 3)],
          timeRange: timeRange,
        };
      }
    } else {
      if (currentRange) {
        ranges.push(currentRange);
        currentRange = null;
      }
    }
  });

  if (currentRange) {
    ranges.push(currentRange);
  }

  return ranges.map((range) => {
    const days = range.days;
    if (days.length === 1) {
      return `${days[0]}: ${range.timeRange}`;
    } else {
      return `${days[0]}-${days[days.length - 1]}: ${range.timeRange}`;
    }
  });
};

export const extractPhoneNumber = (text) => {
  if (text) {
    const regex = /(\d{3}-\d{3}-\d{4})/;
    const match = text.match(regex);
    if (match) {
      return match[0].replace(/-/g, ""); // Remove hyphens
    }
    return null;
  } else {
    return null;
  }
};
