import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "../sidebar/sidebar";
import { useSelector, useDispatch } from "react-redux";
import { getPaymentMethodById } from "../../../redux/action/User";
import "./payment-method.scss";
const PaymentMethod = () => {
  const dispatch = useDispatch();

  const { user } = useSelector(state => state.auth);
  const { paymentMethod } = useSelector(state => state.user);
  useEffect(() => {
    dispatch(getPaymentMethodById(user._id));
  }, [user._id]);

  const paymentData =
    paymentMethod !== "undefined" &&
    Object.keys(paymentMethod).length &&
    paymentMethod?.data.length
      ? paymentMethod.data[0]
      : null;

  return (
    <>
      <section className="user-home">
        <Container fluid>
          <Row>
            <Col md={4}>
              <Sidebar />
            </Col>
            <Col md={7}>
              <div className="wraper">
                <div className="modul-header mb-4">
                  <h2>Payment Methods</h2>
                  <p>
                    Manage your credit card and debit card saved on the account.
                    <br />
                    Update the payment method as needed.
                  </p>
                </div>
                {paymentData ? (
                  <div className="edit-personal payment">
                    <div className="personal-box">
                      <div className="upper-header">
                        <h3>Payment Method</h3>
                        <a href="#">Edit Payment Method</a>
                      </div>
                      <div className="inner-body">
                        {/* <div className="payment-error">
                          ERROR: Please update your payment method.
                        </div> */}
                        <p>
                          <strong>Name of Card</strong>
                          {paymentData.name_of_card}
                        </p>
                        <p>
                          <strong>Credit / Debit Card Number</strong>{" "}
                          ***********{paymentData.last_4_digits}
                        </p>
                        <p>
                          <strong>Expiration Date</strong>
                          {paymentData.expire}
                        </p>
                        <p>
                          <strong>CVC</strong>
                          {paymentData.cvv}
                        </p>
                        <p>
                          <strong>Zip Code</strong>
                          {paymentData.zipcode}
                        </p>
                        <p className="note">
                          This card will be used for all upcoming installment
                          payments.
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <p>No data Found</p>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default PaymentMethod;
