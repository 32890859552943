import { fork, all, takeLatest, call, put } from "redux-saga/effects";
import { RESULT_LIST_REQUEST, RESULT_LIST_SUCCESS } from "../constants";
import { resultListAction } from "../services/api";
function* watchResultList() {
  yield takeLatest(RESULT_LIST_REQUEST, getResultFromAPI);
}
function* getResultFromAPI(action) {
  const resultList = yield call(resultListAction, action.payload);
  if (resultList.data.statusCode === 200) {
    yield put({
      type: RESULT_LIST_SUCCESS,
      payload: resultList.data.data
    });
  }
}
export default function* resultSaga() {
  yield all([fork(watchResultList)]);
}
