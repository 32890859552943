import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import { Col, Row, Container } from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCoursesList } from "../../redux/action/Courses";
import { changePaymentType } from "../../redux/action/Cart";
import "./cart.scss";

const Cart = () => {
  const location = useLocation();
  const [activeFirst, setActiveFirst] = useState("first");
  const [activeSecond, setActiveSecond] = useState();
  const dispatch = useDispatch();
  const { items: cartItems, type } = useSelector(state => {
    return state.cart;
  });
  const { courseList } = useSelector(state => {
    return state.courses;
  });

  const { isLoggedIn } = useSelector(state => state.auth);

  const currencyFormat = num => {
    if (num)
      return (
        "$" +
        parseFloat(num)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      );
    else {
      return "$ 0.00";
    }
  };

  let totalDays = 0;

  const ids = cartItems.map(m => m.courseId);
  const items = courseList.filter(m => ids.includes(m._id));
  useEffect(() => {
    // if (!courseList.length || items.length !== cartItems.length) {
    //   dispatchEvent(getCoursesList({ place: "" }));
    // }
    dispatch(getCoursesList({ place: "", count: 1000, page: 1 }));
  }, [dispatch]);

  const handleActiveStatus = (e, first, second, item) => {
    if (item.payment_type === "Both") {
      setActiveFirst(first);
      setActiveSecond(second);
      let paytype = "";
      paytype = e.target.value;
      if (paytype !== "") {
        dispatch(changePaymentType({ paymentType: paytype }));
      } else {
        dispatch(changePaymentType({ paymentType: item.payment_type }));
      }
    } else {
      return true;
    }
  };
  if (isLoggedIn) {
    return <Redirect to="/checkout/payment" />;
  }
  return (
    <>
      <section className="cart-section">
        <Container>
          <Row>
            <Col md={12}>
              <div className="cart-step">
                <a href="# " className="active">
                  <div className="count">
                    <span> 1</span>
                    My Cart
                  </div>
                </a>
                <a href="# ">
                  <div className="count">
                    <span> 2 </span>
                    Checkout
                  </div>
                </a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div className="contact-outer">
                <div className="new-here">
                  <div className="inside-cont">
                    <h3>New Here?</h3>
                    <Link to="/checkout/isSignUp">
                      Create An Account | Checkout
                    </Link>
                  </div>
                </div>
                <div className="or-divide">
                  <span>or</span>
                </div>
                <div className="new-here">
                  <div className="inside-cont">
                    <h3>Already have an account?</h3>
                    <Link
                      to={{
                        pathname: "/sign-in",
                        state: { location: location.pathname }
                      }}
                    >
                      Sign In | Checkout
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6}>
              <div className="payment-outer">
                <div className="order-summery">
                  <h3>Order Summary</h3>

                  {items?.length
                    ? items.map((item, index) => {
                        const isPrivate =
                          item.subcategory_name.indexOf("Private") !== -1;

                        return (
                          <>
                            <div className="three-points" key={`item_${index}`}>
                              <div className="left">
                                <p>
                                  {item.subcategory_name} - {item.service_type}{" "}
                                  <br />
                                </p>
                                {!item.only_description ? (
                                  <span>
                                    {isPrivate ? (
                                      <p>{item.noof_hours} Hours</p>
                                    ) : (
                                      `${moment(item.start_date).format(
                                        "MMM D"
                                      )} -
                                  ${moment(item.end_date).format("MMM D YYYY")}`
                                    )}
                                    <br />

                                    {isPrivate
                                      ? ""
                                      : `${item.days[0].day}-
                                  ${item.days[item.days.length - 1].day} 
                                  (${item.noof_classes} Classes)`}

                                    {isPrivate ? (
                                      <div
                                        className="timeline"
                                        dangerouslySetInnerHTML={{
                                          __html: item.description,
                                        }}
                                      ></div>
                                    ) : (
                                      <>
                                        {" "}
                                        <br />
                                        {item.start_time} - {item.end_time}
                                        <br />
                                        {item.location} <br />
                                        <br />
                                        <div
                                          className="timeline"
                                          dangerouslySetInnerHTML={{
                                            __html: item.description,
                                          }}
                                        ></div>
                                      </>
                                    )}
                                  </span>
                                ) : (
                                  <>
                                    <br />
                                    {item.only_desc_showdate && (
                                      <p>
                                        {moment(item.start_date).format(
                                          "MMM D"
                                        )}{" "}
                                        -
                                        {moment(item.end_date).format(
                                          "MMM D YYYY"
                                        )}
                                      </p>
                                    )}
                                    <div
                                      className="timeline"
                                      dangerouslySetInnerHTML={{
                                        __html: item.description,
                                      }}
                                    ></div>
                                  </>
                                )}
                              </div>
                              <div className="right">
                                <span>{`${
                                  item.discount === 0
                                    ? ""
                                    : currencyFormat(item.subtotal)
                                }`}</span>
                                <p>{currencyFormat(item.total)}</p>
                              </div>
                            </div>
                            {item.discount !== 0 ? (
                              <div className="cupon">
                                <span>
                                  <strong>
                                    <input
                                      type="text"
                                      placeholder="applyhere"
                                      value={`Save${currencyFormat(
                                        item.discount
                                      )}`}
                                      readOnly
                                    />
                                  </strong>
                                </span>
                                <button type="submit" className="apply-btn">
                                  Applied!
                                </button>
                              </div>
                            ) : null}

                            <div className="select-payment">
                              <h6>Select Payment Option</h6>
                              <div className="payment-option">
                                <a
                                  className={`oneoff ${
                                    activeFirst &&
                                    (item.payment_type === "Full" ||
                                      item.payment_type === "Both")
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  <input
                                    type="radio"
                                    name="payment_type"
                                    defaultValue="Full"
                                    onChange={(e) =>
                                      handleActiveStatus(e, "first", "", item)
                                    }
                                  />
                                  <small>
                                    <strong>Pay In Full</strong>
                                  </small>
                                  <small className="mt-1 mb-3">Due Today</small>
                                  <span>{currencyFormat(item.total)}</span>
                                </a>
                                <div className="or-cricle">or</div>

                                <a
                                  className={`oneoff ${
                                    activeSecond ||
                                    item.payment_type === "Installment"
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  <input
                                    type="radio"
                                    name="payment_type"
                                    defaultValue="Installment"
                                    onChange={(e) =>
                                      handleActiveStatus(e, "", "second", item)
                                    }
                                  />
                                  <small>
                                    <strong>Pay In Installments</strong>
                                  </small>
                                  {item.payment_type !== "Full" ? (
                                    <>
                                      <p>
                                        Due Today
                                        <span>
                                          <span>
                                            {currencyFormat(
                                              item.emi_details.due_today
                                            )}
                                          </span>
                                        </span>
                                      </p>
                                      {item.emi_details.payment_option ===
                                      "Option 1"
                                        ? item.emi_details.installment_details.map(
                                            (_date, i) => {
                                              totalDays =
                                                totalDays +
                                                parseInt(
                                                  _date.paymentoptions_value
                                                );
                                              return (
                                                <p key={`emi_${i}`}>
                                                  {moment()
                                                    .add(totalDays, "days")
                                                    .format("MM/DD/YYYY")}
                                                  <span>
                                                    {currencyFormat(
                                                      _date.value
                                                    )}
                                                  </span>
                                                </p>
                                              );
                                            }
                                          )
                                        : item.emi_details.payment_option ===
                                          "Option 2"
                                        ? item.emi_details.installment_details.map(
                                            (_date, i) => (
                                              <>
                                                <p key={`emi_${i}`}>
                                                  {moment(
                                                    _date.paymentoptions_value
                                                  ).format("MM/DD/YYYY")}
                                                  <span>
                                                    {currencyFormat(
                                                      _date.value
                                                    )}
                                                  </span>
                                                </p>
                                              </>
                                            )
                                          )
                                        : item.emi_details.installment_details.map(
                                            (_date, i) => (
                                              <p key={`emi_${i}`}>
                                                {moment()
                                                  .add(
                                                    _date.paymentoptions_value,
                                                    "days"
                                                  )
                                                  .format("MM/DD/YYYY")}
                                                <span>
                                                  {currencyFormat(_date.value)}
                                                </span>
                                              </p>
                                            )
                                          )}
                                    </>
                                  ) : null}
                                </a>
                              </div>
                            </div>
                          </>
                        );
                      })
                    : null}
                  <div className="total-payment">
                    <p>
                      Subtotal
                      <span>
                        {items.reduce(function(sum, current) {
                          const subFormat = sum + current.subtotal;
                          return currencyFormat(subFormat);
                        }, 0)}
                      </span>
                    </p>
                    <p>
                      Discount
                      <span>
                        -{" "}
                        {items.reduce(function(sum, current) {
                          const discountFormat = sum + current.discount;
                          return currencyFormat(discountFormat);
                        }, 0)}
                      </span>
                    </p>
                    <p>
                      Total
                      <span>
                        {items.reduce(function(sum, current) {
                          const totalFormat = sum + current.total;
                          return currencyFormat(totalFormat);
                        }, 0)}
                      </span>
                    </p>
                  </div>
                  <div className="final-amount">
                    <p>
                      PAY TODAY
                      <span>
                        {`${
                          type?.paymentType === "Installment" ||
                          items[0]?.payment_type === "Installment"
                            ? currencyFormat(items[0]?.emi_details?.due_today)
                            : currencyFormat(items[0]?.total)
                        } USD`}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="payment-refund">
        <Container>
          <div className="refund-policy">
            <Row>
              <Col md={5}>
                <h2>
                  Bobby-Tariq<sup>R</sup> Guarantee
                </h2>
                <p className="text-center">buy With Confidence</p>
              </Col>
              <Col md={7}>
                <Row>
                  <Col md={6}>
                    <div className="refund-box">
                      <img
                        src="/images/presentation.svg"
                        className="img-fluid"
                        alt="presentation"
                      />

                      <p>Full refunds of unused classes</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="refund-box">
                      <img
                        src="/images/contract.svg"
                        className="img-fluid"
                        alt="contract"
                      />
                      <p>No Fixed Term Contracts</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="refund-box">
                      <img
                        src="/images/lecture.svg"
                        className="img-fluid"
                        alt="lecture"
                      />
                      <p>In Person & Online Classes</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="refund-box">
                      <img
                        src="/images/courses.svg"
                        className="img-fluid"
                        alt="courses"
                      />
                      <p>Courses Available Anytime Of The Year</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};
export default Cart;
