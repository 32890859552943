import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { showModalById } from "../../redux/action/Courses";
import { getDaysBetweenDates } from "../../common/calculateDates";

import { Modal, Table } from "react-bootstrap";
import "./schedules-modal.scss";

const SchedulesModal = props => {
  const { courseList, modalCourseId } = useSelector(state => {
    return state.courses;
  });
  const dispatch = useDispatch();
  const hideModal = useCallback(() => {
    dispatch(showModalById(null));
  }, [dispatch]);

  const courseSchedule = courseList?.find(m => m._id === modalCourseId);
  let classesDays;
  if (courseSchedule?._id) {
    classesDays = getDaysBetweenDates(
      new Date(courseSchedule.start_date),
      new Date(courseSchedule.end_date),
      courseSchedule.days
    );
  }
  return (
    <>
      {courseSchedule?._id ? (
        <Modal
          show={!!modalCourseId}
          onHide={hideModal}
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="modal-head">
              <p>
                <strong>Course Schedule:</strong>
                <br /> {moment(courseSchedule.start_date).format(
                  "MMMM D YYYY"
                )}{" "}
                - {moment(courseSchedule.end_date).format("MMMM D YYYY")}
              </p>
              <div className="add-card-box">
                <p>
                  <small>
                    {courseSchedule.discount !== 0
                      ? `$ ${courseSchedule.subtotal}`
                      : ""}
                  </small>
                  ${courseSchedule.total}
                </p>
                <a href="# " className="add-card">
                  Add To Cart
                </a>
              </div>
            </div>
            <div className="item-table">
              <Table responsive>
                <thead>
                  <tr>
                    <th>Day</th>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Session Number</th>
                  </tr>
                </thead>
                <tbody>
                  {classesDays.map((_date, i) => (
                    <tr key={i}>
                      <td>{moment(_date.date).format("dddd")}</td>
                      <td>{moment(_date.date).format("MMM D YYYY")}</td>
                      <td>{`${courseSchedule.start_time}-${courseSchedule.end_time}`}</td>
                      <td>Session {i + 1}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  );
};
export default SchedulesModal;
