import React, { useCallback, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { map } from "../../../common/calculateDates";
import { addToCart, removeFromCart } from "../../../redux/action/Cart";
import { showModalById } from "../../../redux/action/Courses";
import { useSelector, useDispatch } from "react-redux";
import { currencyFormat } from "../../../common/years";

import "../in-person/in-person";

const LiveOnline = props => {
  const history = useHistory();
  const { courses } = props;
  const [showItem, setShowItem] = useState(3);
  const [expanded, setExpanded] = useState(false);
  const { items } = useSelector(state => {
    return state.cart;
  });

  const dispatch = useDispatch();
  const showModal = useCallback(
    id => () => {
      dispatch(showModalById(id));
    },
    [dispatch]
  );
  const handleAddToCart = useCallback((item, isExists) => {
    if (isExists) {
      dispatch(removeFromCart(item._id));
    } else {
      dispatch(
        addToCart({ courseId: item._id, price: item.total, quantity: 1 })
      );
      history.push("/cart");
    }
  });

  const showMore = useCallback(() => {
    if (showItem === 3) {
      setShowItem(courses.length);
      setExpanded(true);
    } else {
      setShowItem(3);
      setExpanded(false);
    }
  }, [courses, showItem]);
  return (
    <>
      <Row>
        <Col sm={12}>
          <div className="course-table">
            {courses.length ? <h3>LIVE ONLINE</h3> : null}
            {courses.slice(0, showItem).map((_course, index) => {
              const isExists = items.some(m => m.courseId === _course._id);
              const isPrivate =
                _course.subcategory_name.indexOf("Private") !== -1;
              return (
                <div className="full-row" key={index}>
                  <ul>
                    <li>
                      <strong>
                        {isPrivate
                          ? `${_course.noof_hours} Hours`
                          : `${moment(_course.start_date).format("MMM D")} -
                            ${moment(_course.end_date).format("MMM D")}`}
                      </strong>
                    </li>
                    <li>
                      {isPrivate ? (
                        <span
                          className="timeline"
                          dangerouslySetInnerHTML={{
                            __html: _course.description
                          }}
                        ></span>
                      ) : !_course.only_description ? (
                        <>
                          {_course.show_firstlast
                            ? `${_course.first_day}-${_course.last_day}`
                            : _course.days.length
                            ? _course.days
                                .sort(function(a, b) {
                                  return map[a.day] - map[b.day];
                                })
                                .map(val => val.day)
                                .join(",")
                            : ""}{" "}
                          <strong>({`${_course.noof_classes} Classes`})</strong>{" "}
                          <span className="timeline">{`${_course.start_time} - ${_course.end_time}`}</span>
                          <span
                            className="timeline"
                            dangerouslySetInnerHTML={{
                              __html: _course.description
                            }}
                          ></span>
                        </>
                      ) : (
                        <span
                          className="timeline"
                          dangerouslySetInnerHTML={{
                            __html: _course.description
                          }}
                        ></span>
                      )}
                    </li>
                    <li>
                      <span>{`${
                        _course.discount === 0
                          ? ""
                          : `${currencyFormat(_course.subtotal)}`
                      }`}</span>
                      <p className="green">{currencyFormat(_course.total)}</p>
                    </li>
                    <li>
                      {_course.only_description ? (
                        <button className="btn btn-outline" disabled={true}>
                          See Schedule
                        </button>
                      ) : !isPrivate && !_course.only_description ? (
                        <button
                          className="btn btn-outline"
                          onClick={showModal(_course._id)}
                        >
                          See Schedule
                        </button>
                      ) : null}
                      <button
                        key={_course._id}
                        className="btn btn-primery"
                        onClick={() => handleAddToCart(_course, isExists)}
                      >
                        {isExists ? "Remove" : "Add to Cart"}
                      </button>
                    </li>
                  </ul>
                </div>
              );

              // ) : null;
            })}
          </div>
        </Col>
      </Row>
      {courses.length > 3 ? (
        <Row>
          <Col sm={12} className="text-center">
            <button
              className="btn btn-outline gutter_bottom"
              onClick={showMore}
            >
              {expanded ? <span>Show less</span> : <span>Show more</span>}
            </button>
          </Col>
        </Row>
      ) : null}
    </>
  );
};
export default LiveOnline;
