export const initialValues = userData => {
  if (userData && Object.keys(userData).length) {
    const data = { ...userData };
    return data;
  }
  return {
    firstName: "",
    lastName: "",
    email: "",
    parent_details: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: {
        apartment: "",
        street: "",
        city: "",
        zipcode: "",
        state: ""
      }
    }
  };
};
