import { fork, all, takeLatest, call, put } from "redux-saga/effects";
import {
  COURSES_LIST_REQUEST,
  COURSES_LIST_SUCCESS,
  COURSES_REQUEST,
  COURSES_SUCCESS
} from "../constants";
import { courseByIdAction, courseListAction } from "../services/api";
function* watchCourseList() {
  yield takeLatest(COURSES_LIST_REQUEST, getCourseFromAPI);
  yield takeLatest(COURSES_REQUEST, getCourseBySubCategoryId);
}
function* getCourseFromAPI(action) {
  const courseList = yield call(courseListAction, action.payload.param);
  if (courseList.data.statusCode === 200) {
    //toast.success("success");
    yield put({
      type: COURSES_LIST_SUCCESS,
      payload: courseList.data.data.data
    });
  }
}

function* getCourseBySubCategoryId(action) {
  const courses = yield call(courseByIdAction, action.payload);
  if (courses.data.statusCode === 200) {
    yield put({
      type: COURSES_SUCCESS,
      payload: courses.data.data.data
    });
  }
}
export default function* courseSaga() {
  yield all([fork(watchCourseList)]);
}
