import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./google-review.scss";
import moment from "moment";
import StarRating from "../../common/StarRating";
import { getGoogleReview } from "../../redux/action/Service";
import Loader from "../loader";
import ReactPaginate from "react-paginate";

function findMatchingTexts(category, texts, keywords) {
  // Convert all keywords to lowercase for case-insensitive matching
  const categoryKeywords = keywords[category].map((keyword) =>
    keyword.toLowerCase()
  );

  // Create regex patterns for all keywords in the category
  const patterns = categoryKeywords.map(
    (keyword) => new RegExp(`\\b${keyword}\\b`, "i")
  );

  // Filter texts based on keyword patterns
  return texts?.filter(({ text }) =>
    patterns.some((pattern) => pattern.test(text))
  );
}

// keywords for each category
const keywords = {
  SAT: ["SAT Prep", "SAT", "college", "digital", "CAP", "beast"],
  SHSAT: ["SHSAT", "specialized", "SSHAT", "SHAT", "specialized"],
  "9th Grade SHSAT": [
    "9th Grade SHSAT",
    "Grade 9 SHSAT",
    "9th",
    "8th",
    "eight",
    "ninth",
    "ninth grade",
    "eight",
    "eighth",
    "9th-grade",
  ],
};

const GoogleReview = (props) => {
  const dispatch = useDispatch();
  const [reviews, setReviews] = useState([]);
  const [reviewsToShow, setReviewsToShow] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const { reviewList, isLoading } = useSelector((state) => {
    return state.service;
  });

  useEffect(() => {
    const { location = "Jackson Heights", category } = props;
    let filteredReviews = [];
    const filteredByLocation = reviewList?.data
      ?.filter(({ location: nodeLocation }) => location === nodeLocation)
      .filter(({ text }) => text && text?.length > 100);

    filteredReviews = filteredByLocation;

    if (category) {
      filteredReviews = findMatchingTexts(
        category,
        filteredByLocation,
        keywords
      );
    }
    setReviews(
      filteredReviews?.sort((a, b) => {
        return new Date(b.datetime) - new Date(a.datetime);
      })
    );
  }, [reviewList?.data, props]);

  useEffect(() => {
    setTotal(Math.ceil(reviews?.length / 20));
    setReviewsToShow(reviews?.slice(0, 20));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviews]);

  useEffect(() => {
    dispatch(
      getGoogleReview({
        count: 694,
        rating: 5,
        status: "Active",
        sortBy: "datetime",
      })
    );
  }, [dispatch]);

  const handlePageClick = (data) => {
    let page = data.selected;
    setPage(page);
    const offset = page * 10;
    setReviewsToShow(reviews.slice(offset, offset + 20));
  };

  const handleShowText = (e, i) => {
    var Long = document.getElementById(`long_${i}`);
    var Short = document.getElementById(`short_${i}`);

    if (Long.classList.contains("showComments")) {
      Long.classList.remove("showComments");
      Short.classList.add("showComments");
      e.target.textContent = "Read Less";
    } else if (Short.classList.contains("showComments")) {
      Short.classList.remove("showComments");
      Long.classList.add("showComments");
      e.target.textContent = "Read More";
    }
  };

  let char_limit = 296;
  return (
    <>
      <section className="google-section">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h2 className="section-heading mb-2">
                <StarRating
                  rating={
                    // filteredReviews ? Math?.round(filteredReviews?.avg * 10) / 10 : 0
                    5
                  }
                  dimension={"35px"}
                />
                powered By{" "}
                <img
                  src="/images/google-logo.svg"
                  alt=""
                />{" "}
              </h2>
              {/*  <p className="text-center">
               {filteredReviews ? Math.round((+filteredReviews.avg || 0) * 10) / 10 : ""} 
              5 out of
                5 based on <strong>{filteredReviews?.total_reviews} reviews</strong> on
                Google
              </p>
              */}
            </div>
            {/* .filter(m => m.text.length > 296) */}
            <div className="google-review">
              {isLoading ? (
                <Loader />
              ) : reviewsToShow?.length ? (
                reviewsToShow?.map((rev, i) => {
                  const [first, second] = rev.reviewer.split(" ");
                  const lname = !second ? "" : second.charAt(0) + ".";

                  return (
                    <div key={`review_${i}`} className="rated-box">
                      <div className="rating-head">
                        <div className="avtar-name">
                          <div className="avtar">
                            {rev.reviewer.charAt(0).toUpperCase()}
                          </div>
                          <div className="name-reviw">
                            <h2>{`${first} ${lname}`}</h2>
                            <div className="powered-by">
                              <p>
                                powered by{" "}
                                <img
                                  src="/images/google-logo.svg"
                                  alt=""
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                        <span className="rating-time">
                          <StarRating rating={rev?.rating} dimension={"12px"} />
                          <font>
                            {moment(rev?.datetime).startOf("day").fromNow()}
                          </font>
                        </span>
                      </div>
                      <p className="comments">
                        {rev.text?.length < char_limit ? (
                          rev.text
                        ) : (
                          <>
                            <span className="short-text" id={`short_${i}`}>
                              {rev.text.substr(0, char_limit)}
                            </span>
                            <span
                              className="long-text showComments"
                              id={`long_${i}`}
                            >
                              {rev.text}
                            </span>
                            <span
                              className="show-more-button"
                              onClick={(event) => {
                                handleShowText(event, i);
                              }}
                              data-more="0"
                            >
                              Read More
                            </span>
                          </>
                        )}
                      </p>
                    </div>
                  );
                })
              ) : null}
            </div>
          </div>
          <div className="row" className="center">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={total}
              marginPagesDisplayed={1}
              pageRangeDisplayed={1}
              onPageChange={handlePageClick}
              forcePage={page}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </section>
    </>
  );
};
export default GoogleReview;
