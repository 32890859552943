import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import Home from "../containers/home/home";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import Blog from "../containers/blog/blog";
import Service from "../containers/service/service";
import SingleBlog from "../containers/single-blog/single-blog";
import ContactUs from "../containers/contact-us/contact-us";
import Location from "../containers/location/location";
import Location3 from "../containers/location/location-3";
import UserHome from "../containers/user-module/user-home/user-home";
import PersonalInfo from "../containers/user-module/personal-info/personal-info";
import OrderHistory from "../containers/user-module/order-history/order-history";
import PaymentMethod from "../containers/user-module/payment-method/payment-method";
import Security from "../containers/user-module/security/security";
import Cart from "../containers/cart/cart";
import Checkout from "../containers/checkout/checkout";
import LoginPage from "../containers/login/login";
import ForgotPassword from "../containers/forgot-password";
import TermsAndCondition from "../containers/terms-conditions";
import ScrollToTop from "../common/scrollTop";

import PrivateRoute from "../common/PrivateRoute";

import { useSelector } from "react-redux";
import NotFound from "../components/NotFound/NotFound";
import IdleTimerLogout from "../components/idleTimer";

export const RemoveTrailingSlash = ({ ...rest }) => {
  const location = useLocation();

  // If the last character of the url is '/'
  if (location.pathname.match("/.*/$")) {
    return (
      <Redirect
        replace
        {...rest}
        to={{
          pathname: location.pathname.replace(/\/+$/, ""),
          search: location.search,
        }}
      />
    );
  } else return null;
};

const Routers = () => {
  const { isLoggedIn } = useSelector((state) => state.auth);
  return (
    <Router>
      <Header />
      <RemoveTrailingSlash />
      {isLoggedIn && <IdleTimerLogout />}
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/blog" component={Blog} />

        <Route exact path="/blog/:slug" component={SingleBlog} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/jackson-heights" component={Location} />
        <Redirect from="/astoria" to="/jackson-heights" />
        <Redirect from="/elmhurst" to="/astoria" />
        <Route exact path="/jamaica" component={Location3} />

        <Route exact path="/forgot-password" component={ForgotPassword} />
        <Route
          exact
          path="/terms-and-conditions"
          component={TermsAndCondition}
        />
        <Route exact path="/sign-in" component={LoginPage} />
        <PrivateRoute
          exact
          path="/user-home"
          isLoggedIn={isLoggedIn}
          component={UserHome}
        />
        <PrivateRoute
          exact
          path="/personal-info"
          isLoggedIn={isLoggedIn}
          component={PersonalInfo}
        />
        <PrivateRoute
          exact
          path="/order-history"
          isLoggedIn={isLoggedIn}
          component={OrderHistory}
        />
        <PrivateRoute
          exact
          path="/payment-method"
          isLoggedIn={isLoggedIn}
          component={PaymentMethod}
        />
        <Route exact path="/security" component={Security} />
        <Route exact path="/cart" component={Cart} />
        <Route
          exact
          path="/checkout/:id(isSignUp|payment)"
          component={Checkout}
        />
        <Route
          exact
          path="/:slug(sat-prep|shsat-prep|9th-grade-shsat-prep|sat-private-tutoring|shsat-private-tutoring|9th-grade-shsat-private-tutoring)"
          component={Service}
        />
        <Route path="*" component={NotFound} />
      </Switch>
      <Footer />
      <ScrollToTop />
    </Router>
  );
};

export default Routers;
