import "./App.css";
import Routers from "./routers/routers";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import store from "./redux";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { persistStore } from "redux-persist";
import Loader from "./components/loader";

const CloseButton = ({ closeToast }) => (
  <i onClick={closeToast} className="la la-close notifications-close" />
);

function App() {
  const persistor = persistStore(store);

  return (
    <div className="App">
      <ToastContainer
        autoClose={10000}
        hideProgressBar={false}
        closeButton={<CloseButton />}
      />

     
      <Provider store={store}>
        <PersistGate loading={<Loader />} persistor={persistor}>
          <BrowserRouter>
            <Routers />
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
