import {
  ORDER_REQUEST,
  ORDER_SUCCESS,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_PAYMENT_REQUEST,
  USER_PAYMENT_SUCCESS,
  USER_DETAILS_UPDATE_REQUEST,
  USER_DETAILS_UPDATE_SUCCESS
} from "../constants";
const initialState = {
  isLoading: false,
  orderList: [],
  userDetails: {},
  paymentMethod: {}
};
const UserReducer = (
  state = initialState,
  { type = null, payload = null } = {}
) => {
  switch (type) {
    case ORDER_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        orderList: payload && payload
      };
    case USER_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case USER_DETAILS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userDetails: payload && payload
      };
    case USER_PAYMENT_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case USER_PAYMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        paymentMethod: payload && payload
      };
    case USER_DETAILS_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case USER_DETAILS_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        userDetails: payload && payload
      };
    default:
      return state;
  }
};
export default UserReducer;
