import { RESULT_LIST_REQUEST, RESULT_LIST_SUCCESS } from "../constants";
const initialState = {
  isLoading: false,
  resultList: []
};
const ResultVideoReducer = (
  state = initialState,
  { type = null, payload = null } = {}
) => {
  switch (type) {
    case RESULT_LIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        params: payload,
        resultList: []
      };
    case RESULT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        resultList: payload
      };
    default:
      return state;
  }
};
export default ResultVideoReducer;
