import React, { useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import moment from "moment";
import { Col, Row, Container } from "react-bootstrap";
import { getBlog } from "../../redux/action/Blogs";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";

import "./single-blog.scss";
import SEO from "../../components/seo";
const SingleBlog = () => {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBlog({ id: params.slug, history }));
  }, []);
  const handleScroll = useCallback((id) => () => {
    const top = document.getElementById(id)?.offsetTop || 0;
    window.scrollTo({ top, left: 0, behavior: "smooth" });
  });
  const { blog } = useSelector((state) => {
    return state.blogs;
  });
  return (
    <>
      <section className="blog-page">
        <Container>
          <Row className="justify-content-center">
            {blog?.contacts?.length ? (
              <>
                <SEO
                  title={blog.contacts[0].meta_title}
                  description={blog.contacts[0].meta_description}
                  keywords={blog.contacts[0].meta_keywords}
                />
                <Col md={8}>
                  <small>Bobby Tariq Blog</small>
                  <h2>{blog.contacts[0].title}</h2>
                  <small>
                    {blog.contacts[0].auther},
                    {`  ${moment(blog.contacts[0].createdAt).format(
                      "MMMM D, YYYY"
                    )}`}
                  </small>
                  <div
                    className="single-blog-cont"
                    dangerouslySetInnerHTML={{
                      __html: `${blog.contacts[0].description}`,
                    }}
                  ></div>
                </Col>
              </>
            ) : null}
          </Row>
        </Container>
        <div className="fixed-social">
          <ul>
            <li>
              <a
                href="https://www.youtube.com/channel/UCPGlut9qBdo162SK8vwin6g"
                target="_blank"
                rel="noreferrer"
                className="nav-link"
              >
                <img
                  src="/images/youtube-icon.svg"
                  className="img-fluid"
                  alt="youtube"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/bobby_tariq/?hl=en"
                target="_blank"
                rel="noreferrer"
                className="nav-link"
              >
                <img
                  src="/images/instagram-icon.svg"
                  className="img-fluid"
                  alt="instagram"
                />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/BobbyTariqTutoringCenter/"
                target="_blank"
                rel="noreferrer"
                className="nav-link"
              >
                <img
                  src="/images/facebook-icon.svg"
                  className="img-fluid"
                  alt="facebook"
                />
              </a>
            </li>
            <li>
              <a
                href="https://twitter.com/realbobbytariq?lang=en"
                target="_blank"
                rel="noreferrer"
                className="nav-link"
              >
                <img
                  src="/images/twitter-icon.svg"
                  className="img-fluid"
                  alt="twitter"
                />
              </a>
            </li>
          </ul>
        </div>
      </section>

      <section className="green-search">
        <Container>
          <Row>
            <Col sm={12}>
              <div className=" green-container">
                <Link to="/contact-us" className="contact-us-btn">
                  Contact Us
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default SingleBlog;
