import React, { useEffect } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import Sidebar from "../sidebar/sidebar";
import { getOrdersByUserId } from "../../../redux/action/User";
import "./order-history.scss";
import { baseUrl } from "../../../ennviroment";
const OrderHistory = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);

  useEffect(() => {
    if (user) {
      dispatch(getOrdersByUserId(user._id));
    }
  }, [user]);

  const { orderList } = useSelector(state => state.user);
  // console.log(orderList);
  return (
    <>
      <section className="user-home">
        <Container fluid>
          <Row>
            <Col md={4}>
              <Sidebar />
            </Col>
            <Col md={7}>
              <div className="wraper">
                <div className="modul-header mb-4">
                  <h2>Order History</h2>
                  <p>
                    Access your registration form, purchase history, and <br />
                    payment status.
                  </p>
                </div>
                <div className="edit-personal">
                  <div className="personal-box">
                    <div className="upper-header">
                      <h3>Order History</h3>
                    </div>
                    <div className="table-body table-responsive">
                      <Table bordered>
                        <thead>
                          <tr>
                            <th>Order ID</th>
                            <th>Date</th>
                            <th>Course Name</th>
                            <th>Price</th>
                            {/* <th>Payment Status</th> */}
                            <th>Payment Type</th>
                            <th>Installment Date</th>
                            <th>PDF Invoice</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orderList.data?.map((_order, i) => (
                            <tr key={`order${i}`}>
                              <td>{_order.eorderId}</td>
                              <td>
                                {moment(_order.createdAt).format("MM/DD/YY")}
                              </td>
                              <td>{_order.courses[0].name}</td>
                              <td>{_order.courses[0].price}</td>
                              {/* <td>{_order.payment_status}</td> */}
                              <td>{_order.paymentType}</td>
                              {_order.paymentType === "Installment" ? (
                                <td>
                                  {_order.instalmentsDate.map((val, i) => (
                                    <p key={`date${i}`}>
                                      {moment(val).format("MM/DD/YY")} - $
                                      {_order.first_amount}.00{" "}
                                      {moment() > moment(val) ? (
                                        <span>(Paid)</span>
                                      ) : (
                                        ""
                                      )}
                                    </p>
                                  ))}
                                </td>
                              ) : (
                                <td>N/A</td>
                              )}
                              <td>
                                {" "}
                                <a
                                  href={
                                    `${baseUrl}api/v1/pdf/generate-pdf?orderId=` +
                                    _order._id
                                  }
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img src="/images/pdf-icon.svg" alt="icon" />
                                </a>
                              </td>
                              {/*<td>
                                <p>Partial Paid</p>
                                <span className="error">
                                  2nd Payment Failed Update Payment
                                </span>
                              </td>
                              <td>
                                <p>
                                  05/04/21 - $1,500<span>(Paid)</span>
                                </p>
                                <p>05/22/21 - $1,000</p>
                                <span className="error">
                                  (Failed - Update Payment)
                                </span>
                                <p>06/15/21 - $500</p>
                                <span className="defult">(Not Due Yet)</span>
                              </td>
                              <td>
                                {" "}
                                <a href="#">
                                  <img src="/images/pdf-icon.svg" alt="icon" />
                                </a>
                              </td> */}
                            </tr>
                          ))}

                          {/* <tr>
                            <td>2314</td>
                            <td>04/11/21</td>
                            <td>SHSAT Prep</td>
                            <td>$2,500</td>
                            <td>Full Paid</td>
                            <td></td>
                            <td>
                              {" "}
                              <a href="#">
                                <img src="/images/pdf-icon.svg" alt="icon" />
                              </a>
                            </td>
                          </tr> */}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default OrderHistory;
