import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
const ProcessLoader = props => {
  return (
    <Loader
      type="ThreeDots"
      color="#5ec19b"
      height={25}
      width={25}
      visible={props.isLoading}
      //   timeout={3000} //3 secs
    />
  );
};

export default ProcessLoader;
