import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
const NotFound = () => {
  return (
    <>
      <section className="not-found">
        <Container>
          <Row>
            <Col md={12}>
              <h1>404</h1>
              <h2>The page you are requested could not be found</h2>
              <Link to="/">Back to Home</Link>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default NotFound;
