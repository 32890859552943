import React, { useCallback, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { map } from "../../../common/calculateDates";
import { showModalById } from "../../../redux/action/Courses";
import { addToCart, removeFromCart } from "../../../redux/action/Cart";
import { useSelector, useDispatch } from "react-redux";
import { currencyFormat } from "../../../common/years";

import "./in-person.scss";

const InPerson = props => {
  const history = useHistory();
  const [showJacksonItem, setShowJacksonItem] = useState(3);
  const [showItem, setShowItem] = useState(3);
  const [expanded, setExpanded] = useState(false);
  const [jacksonExpanded, setJacksonExpanded] = useState(false);
  const { items } = useSelector(state => {
    return state.cart;
  });

  const { courses } = props;
  const dispatch = useDispatch();
  const showModal = useCallback(
    id => () => {
      dispatch(showModalById(id));
    },
    [dispatch]
  );

  const handleAddToCart = useCallback((item, isExists) => {
    if (isExists) {
      dispatch(removeFromCart(item._id));
    } else {
      dispatch(
        addToCart({ courseId: item._id, price: item.total, quantity: 1 })
      );
      history.push("/cart");
    }
  });

  const jacksonList = courses.filter(
    course => course.location === "Jackson Heights"
  );

  const elmList = courses.filter(course => course.location === "Astoria");
  const jamaicaList = courses.filter(course => course.location === "Jamaica");

  const showJacksonMore = useCallback(() => {
    if (showJacksonItem === 3) {
      setShowJacksonItem(courses.length);
      setJacksonExpanded(true);
    } else {
      setShowJacksonItem(3);
      setJacksonExpanded(false);
    }
  }, [courses, showJacksonItem]);

  const showMore = useCallback(() => {
    if (showItem === 3) {
      setShowItem(courses.length);
      setExpanded(true);
    } else {
      setShowItem(3);
      setExpanded(false);
    }
  }, [courses, showItem]);

  return (
    <>
      <Row>
        <Col sm={12}>
          <div className="course-table">
            <h3>IN PERSON</h3>
            {jacksonList?.length ? (
              <p className="mb-1">
                Jackson Heights, NY |
                <a
                  href="https://www.google.com/maps/place/Bobby-Tariq+Tutoring+Center/@40.749129,-73.891424,16z/data=!4m5!3m4!1s0x0:0xac9242eebe218d19!8m2!3d40.7491223!4d-73.8914227?hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  View Map
                </a>{" "}
              </p>
            ) : null}

            {jacksonList.slice(0, showJacksonItem).map((_course, index) => {
              const isExists = items.some(m => m.courseId === _course._id);
              const isPrivate =
                _course.subcategory_name.indexOf("Private") !== -1;

              return (
                <div className="full-row" key={index}>
                  {isPrivate}
                  <ul>
                    <li>
                      <strong>
                        {isPrivate
                          ? `${_course.noof_hours} Hours`
                          : `${moment(_course.start_date).format("MMM D")} -
                            ${moment(_course.end_date).format("MMM D")}`}
                      </strong>
                    </li>
                    <li>
                      {isPrivate ? (
                        <span
                          className="timeline"
                          dangerouslySetInnerHTML={{
                            __html: _course.description
                          }}
                        ></span>
                      ) : !_course.only_description ? (
                        <>
                          {_course.show_firstlast
                            ? `${_course.first_day}-${_course.last_day}`
                            : _course.days.length
                            ? _course.days
                                .sort(function(a, b) {
                                  return map[a.day] - map[b.day];
                                })
                                .map(val => val.day)
                                .join(",")
                            : ""}{" "}
                          <strong>({`${_course.noof_classes} Classes`})</strong>{" "}
                          <span className="timeline">{`${_course.start_time} - ${_course.end_time}`}</span>
                          <span
                            className="timeline"
                            dangerouslySetInnerHTML={{
                              __html: _course.description
                            }}
                          ></span>
                        </>
                      ) : (
                        <span
                          className="timeline"
                          dangerouslySetInnerHTML={{
                            __html: _course.description
                          }}
                        ></span>
                      )}
                    </li>
                    <li>
                      <span>{`${
                        _course.discount === 0
                          ? ""
                          : `${currencyFormat(_course.subtotal)}`
                      }`}</span>
                      <p className="green">{currencyFormat(_course.total)}</p>
                    </li>
                    <li>
                      {_course.only_description ? (
                        <button className="btn btn-outline" disabled={true}>
                          See Schedule
                        </button>
                      ) : !isPrivate && !_course.only_description ? (
                        <button
                          className="btn btn-outline"
                          onClick={showModal(_course._id)}
                        >
                          See Schedule
                        </button>
                      ) : null}

                      <button
                        key={_course._id}
                        className="btn btn-primery"
                        onClick={() => handleAddToCart(_course, isExists)}
                      >
                        {isExists ? "Remove" : "Add to Cart"}
                      </button>
                    </li>
                  </ul>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
      {jacksonList.length > 3 ? (
        <Row>
          <Col sm={12} className="text-center">
            <button
              className="btn btn-outline gutter_bottom"
              onClick={showJacksonMore}
            >
              {jacksonExpanded ? (
                <span>Show less</span>
              ) : (
                <span>Show more</span>
              )}
            </button>
          </Col>
        </Row>
      ) : null}
       {/* // jamaica */}
       <Row>
        <Col sm={12}>
          {jamaicaList?.length ? (
            <div className="course-table">
              <p className="mb-1">
                Jamaica, NY |
                <a
                  href="https://www.google.com/maps/place/Bobby-Tariq+Tutoring+Center/@40.7113195,-73.7903684,17z/data=!4m12!1m6!3m5!1s0x89c261dfec4e9301:0xb3dc4bc3b555a3ae!2sBobby-Tariq+Tutoring+Center!8m2!3d40.7113195!4d-73.7903684!3m4!1s0x89c261dfec4e9301:0xb3dc4bc3b555a3ae!8m2!3d40.7113195!4d-73.7903684"
                   target="_blank"
                  rel="noreferrer"
                >
                  View Map
                </a>{" "}
              </p>

              {jamaicaList.slice(0, showItem).map((_course, index) => {
                const isExists = items.some(m => m.courseId === _course._id);
                const isPrivate =
                  _course.subcategory_name.indexOf("Private") !== -1;
                return (
                  <div className="full-row" key={index}>
                    {isPrivate}
                    <ul>
                      <li>
                        <strong>
                          {isPrivate
                            ? `${_course.noof_hours} Hours`
                            : `${moment(_course.start_date).format("MMM D")} -
                            ${moment(_course.end_date).format("MMM D")}`}
                        </strong>
                      </li>
                      <li>
                        {isPrivate ? (
                          <span
                            className="timeline"
                            dangerouslySetInnerHTML={{
                              __html: _course.description
                            }}
                          ></span>
                        ) : !_course.only_description ? (
                          <>
                            {_course.show_firstlast
                              ? `${_course.first_day}-${_course.last_day}`
                              : _course.days.length
                              ? _course.days
                                  .sort(function(a, b) {
                                    return map[a.day] - map[b.day];
                                  })
                                  .map(val => val.day)
                                  .join(",")
                              : ""}{" "}
                            <strong>
                              ({`${_course.noof_classes} Classes`})
                            </strong>{" "}
                            <span className="timeline">{`${_course.start_time} - ${_course.end_time}`}</span>
                            <span
                              className="timeline"
                              dangerouslySetInnerHTML={{
                                __html: _course.description
                              }}
                            ></span>
                          </>
                        ) : (
                          <span
                            className="timeline"
                            dangerouslySetInnerHTML={{
                              __html: _course.description
                            }}
                          ></span>
                        )}
                      </li>
                      <li>
                        <span>{`${
                          _course.discount === 0
                            ? ""
                            : `${currencyFormat(_course.subtotal)}`
                        }`}</span>
                        <p className="green">{currencyFormat(_course.total)}</p>
                      </li>
                      <li>
                        {_course.only_description ? (
                          <button className="btn btn-outline" disabled={true}>
                            See Schedule
                          </button>
                        ) : !isPrivate && !_course.only_description ? (
                          <button
                            className="btn btn-outline"
                            onClick={showModal(_course._id)}
                          >
                            See Schedule
                          </button>
                        ) : null}

                        <button
                          key={_course._id}
                          className="btn btn-primery"
                          onClick={() => handleAddToCart(_course, isExists)}
                        >
                          {isExists ? "Remove" : "Add to Cart"}
                        </button>
                      </li>
                    </ul>
                  </div>
                );
              })}
            </div>
          ) : null}
        </Col>
      </Row>
      {jamaicaList.length > 3 ? (
        <Row>
          <Col sm={12} className="text-center">
            <button
              className="btn btn-outline gutter_bottom"
              onClick={showMore}
            >
              {expanded ? <span>Show less</span> : <span>Show more</span>}
            </button>
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col sm={12}>
          {elmList?.length ? (
            <div className="course-table">
              <p className="mb-1">
               Astoria, NY |
                <a
                  href="https://www.google.com/maps/place/Bobby-Tariq+Tutoring+Center/@40.744831,-73.889841,16z/data=!4m5!3m4!1s0x0:0xfdc92b4e8beca350!8m2!3d40.7448314!4d-73.8898408?hl=en"
                  target="_blank"
                  rel="noreferrer"
                >
                  View Map
                </a>{" "}
              </p>

              {elmList.slice(0, showItem).map((_course, index) => {
                const isExists = items.some(m => m.courseId === _course._id);
                const isPrivate =
                  _course.subcategory_name.indexOf("Private") !== -1;
                return (
                  <div className="full-row" key={index}>
                    {isPrivate}
                    <ul>
                      <li>
                        <strong>
                          {isPrivate
                            ? `${_course.noof_hours} Hours`
                            : `${moment(_course.start_date).format("MMM D")} -
                            ${moment(_course.end_date).format("MMM D")}`}
                        </strong>
                      </li>
                      <li>
                        {isPrivate ? (
                          <span
                            className="timeline"
                            dangerouslySetInnerHTML={{
                              __html: _course.description
                            }}
                          ></span>
                        ) : !_course.only_description ? (
                          <>
                            {_course.show_firstlast
                              ? `${_course.first_day}-${_course.last_day}`
                              : _course.days.length
                              ? _course.days
                                  .sort(function(a, b) {
                                    return map[a.day] - map[b.day];
                                  })
                                  .map(val => val.day)
                                  .join(",")
                              : ""}{" "}
                            <strong>
                              ({`${_course.noof_classes} Classes`})
                            </strong>{" "}
                            <span className="timeline">{`${_course.start_time} - ${_course.end_time}`}</span>
                            <span
                              className="timeline"
                              dangerouslySetInnerHTML={{
                                __html: _course.description
                              }}
                            ></span>
                          </>
                        ) : (
                          <span
                            className="timeline"
                            dangerouslySetInnerHTML={{
                              __html: _course.description
                            }}
                          ></span>
                        )}
                      </li>
                      <li>
                        <span>{`${
                          _course.discount === 0
                            ? ""
                            : `${currencyFormat(_course.subtotal)}`
                        }`}</span>
                        <p className="green">{currencyFormat(_course.total)}</p>
                      </li>
                      <li>
                        {_course.only_description ? (
                          <button className="btn btn-outline" disabled={true}>
                            See Schedule
                          </button>
                        ) : !isPrivate && !_course.only_description ? (
                          <button
                            className="btn btn-outline"
                            onClick={showModal(_course._id)}
                          >
                            See Schedule
                          </button>
                        ) : null}

                        <button
                          key={_course._id}
                          className="btn btn-primery"
                          onClick={() => handleAddToCart(_course, isExists)}
                        >
                          {isExists ? "Remove" : "Add to Cart"}
                        </button>
                      </li>
                    </ul>
                  </div>
                );
              })}
            </div>
          ) : null}
        </Col>
      </Row>
      {elmList.length > 3 ? (
        <Row>
          <Col sm={12} className="text-center">
            <button
              className="btn btn-outline gutter_bottom"
              onClick={showMore}
            >
              {expanded ? <span>Show less</span> : <span>Show more</span>}
            </button>
          </Col>
        </Row>
      ) : null}

     
    </>
  );
};
export default InPerson;
