import authSaga from "./authSaga";
import resultSaga from "./resultSaga";
import categorySaga from "./categorySaga";
import courseSaga from "./courseSaga";
import subCategoriesSaga from "./subCategoriesSaga";
import slugSaga from "./slugSaga";
import serviceSaga from "./serviceSaga";
import cartSaga from "./cartSaga";
import userSaga from "./userSaga";
import blogSaga from "./blogSaga";

const rootSaga = sagaMiddleware => {
  sagaMiddleware.run(authSaga);
  sagaMiddleware.run(resultSaga);
  sagaMiddleware.run(categorySaga);
  sagaMiddleware.run(courseSaga);
  sagaMiddleware.run(subCategoriesSaga);
  sagaMiddleware.run(slugSaga);
  sagaMiddleware.run(serviceSaga);
  sagaMiddleware.run(cartSaga);
  sagaMiddleware.run(userSaga);
  sagaMiddleware.run(blogSaga);
};
export default rootSaga;
