/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

 function SEO({ description, lang, keywords, image, meta, title }) {
  const siteImage = "/images/logo.svg";

  const site = {
    title: `Bobby-Tariq Tutoring Center`,
    description: `Bobby-Tariq Tutoring Center.`,
    author: `@Bobby-Tariq Center Inc`,
    keywords: "Bobby-Tariq Tutoring Center",
  };

  // const metaDescription = description || site?.description;
  // const metaKeywords = keywords || site?.keywords;
  // const metaImage = image || site?.image;
  const metaDescription = description ? description :  site.description;
  const metaKeywords = keywords ? keywords : site.keywords;
  const metaImage = image ? image : siteImage;
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site?.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: metaKeywords,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:image`,
          content: metaImage,
        },
        {
          name: `twitter:creator`,
          content: site?.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        }
      ].concat(meta)}
    />
  );
}
//max-snippet:20, max-image-preview:large

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  image: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
