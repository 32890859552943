import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider.scss";
import { getResultList } from "../../redux/action/ResultVideos";
import { useSelector, useDispatch } from "react-redux";
export default function SimpleSlider(props) {
  const dispatch = useDispatch();
  const { params } = props;
  const { isLoading, resultList } = useSelector(state => {
    return state.result;
  });
  useEffect(() => {
    dispatch(getResultList(params));
  }, [params.subcategoryId]);
  var settings = {
    dots: false,
    navigator: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  if (isLoading) {
    return <div>Loading....</div>;
  }

  return (
    <Slider {...settings}>
      {resultList.data &&
        resultList.data.map((_result, index) => (
          <div key={index} className="slide-box">
            <div className="media-here">
              <video
                width="100%"
                height="100%"
                preload="none"
                controls
                poster={_result.thumbnailLink?.replace('dax3hbhvk7d4y','d16k95ykg4bhzr')}
              >
                <source src={_result.videoLink?.replace('dax3hbhvk7d4y','d16k95ykg4bhzr')} />
              </video>
            </div>
            <div className="discrption">
              <h4>{_result.title}</h4>
              {/* <p>724 views | Feb 4, 2021</p> */}
            </div>
          </div>
        ))}
    </Slider>
  );
}
