import { fork, all, takeLatest, call, put } from "redux-saga/effects";
// import { toast } from "react-toastify";
import {
  GET_CART_REQUEST,
  GET_CART_SUCCESS,
  SHIFT_CART,
  EMPTY_CART,
  LOGOUT
} from "../constants";
import { cartAction, shiftCartAction } from "../services/api";
function* watchCart() {
  yield takeLatest(GET_CART_REQUEST, getCartFromAPI);
  yield takeLatest(SHIFT_CART, shiftCartFromAPI);
}
function* getCartFromAPI(action) {
  try {
    const cart = yield call(cartAction, action.payload);
    if (cart.data.statusCode === 200) {
      yield put({
        type: GET_CART_SUCCESS,
        payload: cart.data.data[0]?.cart || []
      });
    }
  } catch (err) {
    console.error(err);
  }
}

function* shiftCartFromAPI(action) {
  try {
    const response = yield call(shiftCartAction, action.payload);
    if (response.data.statusCode === 200 && action.payload.logOut) {
      yield put({ type: EMPTY_CART });
      yield put({
        type: LOGOUT
      });
    } else {
      yield put({ type: GET_CART_REQUEST, payload: action.payload.userId });
    }
  } catch (err) {
    console.error(err);
  }
}

export default function* cartSaga() {
  yield all([fork(watchCart)]);
}
