import React from "react";

const Person = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6667 5.83333C11.6667 4.91417 10.9192 4.16667 10 4.16667C9.08085 4.16667 8.33335 4.91417 8.33335 5.83333C8.33335 6.7525 9.08085 7.5 10 7.5C10.9192 7.5 11.6667 6.7525 11.6667 5.83333ZM13.3334 5.83333C13.3334 7.67167 11.8384 9.16667 10 9.16667C8.16169 9.16667 6.66669 7.67167 6.66669 5.83333C6.66669 3.995 8.16169 2.5 10 2.5C11.8384 2.5 13.3334 3.995 13.3334 5.83333ZM4.16669 16.6667C4.16669 13.45 6.78419 10.8333 10 10.8333C13.2159 10.8333 15.8334 13.45 15.8334 16.6667C15.8334 17.1267 15.4609 17.5 15 17.5C14.5392 17.5 14.1667 17.1267 14.1667 16.6667C14.1667 14.3692 12.2975 12.5 10 12.5C7.70252 12.5 5.83335 14.3692 5.83335 16.6667C5.83335 17.1267 5.46085 17.5 5.00002 17.5C4.53919 17.5 4.16669 17.1267 4.16669 16.6667Z"
      fill="currentColor"
    />
    <mask
      id="mask113"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="4"
      y="2"
      width="12"
      height="16"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6667 5.83333C11.6667 4.91417 10.9192 4.16667 10 4.16667C9.08085 4.16667 8.33335 4.91417 8.33335 5.83333C8.33335 6.7525 9.08085 7.5 10 7.5C10.9192 7.5 11.6667 6.7525 11.6667 5.83333ZM13.3334 5.83333C13.3334 7.67167 11.8384 9.16667 10 9.16667C8.16169 9.16667 6.66669 7.67167 6.66669 5.83333C6.66669 3.995 8.16169 2.5 10 2.5C11.8384 2.5 13.3334 3.995 13.3334 5.83333ZM4.16669 16.6667C4.16669 13.45 6.78419 10.8333 10 10.8333C13.2159 10.8333 15.8334 13.45 15.8334 16.6667C15.8334 17.1267 15.4609 17.5 15 17.5C14.5392 17.5 14.1667 17.1267 14.1667 16.6667C14.1667 14.3692 12.2975 12.5 10 12.5C7.70252 12.5 5.83335 14.3692 5.83335 16.6667C5.83335 17.1267 5.46085 17.5 5.00002 17.5C4.53919 17.5 4.16669 17.1267 4.16669 16.6667Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask113)">
      <rect width="20" height="20" fill="currentColor" />
    </g>
  </svg>
);

export default Person;
