import { fork, all, takeLatest, call, put } from "redux-saga/effects";
import {
  SERVICE_REQUEST,
  SERVICE_SUCCESS,
  FAQ_REQUEST,
  FAQ_SUCCESS,
  REVIEW_REQUEST,
  REVIEW_SUCCESS
} from "../constants";
import { serviceAction, faqAction, reviewAction } from "../services/api";
function* watchService() {
  yield takeLatest(SERVICE_REQUEST, getServiceFromAPI);
  yield takeLatest(FAQ_REQUEST, getFAQFromAPI);
  yield takeLatest(REVIEW_REQUEST, getReviewFromAPI);
}
function* getServiceFromAPI(action) {
  try {
    const service = yield call(serviceAction, action.payload.id);
    if (service.data.statusCode === 200) {
      yield put({
        type: SERVICE_SUCCESS,
        payload: service.data.data.data[0]
      });
    }
  } catch (err) {
    console.log(err);
  }
}

function* getFAQFromAPI(action) {
  const faqList = yield call(faqAction, action.payload.id);
  if (faqList.data.statusCode === 200) {
    yield put({
      type: FAQ_SUCCESS,
      payload: faqList.data.data.data
    });
  }
}

function* getReviewFromAPI(action) {
  const reviewList = yield call(reviewAction, action.payload);
  if (reviewList.data.statusCode === 200) {
    yield put({
      type: REVIEW_SUCCESS,
      payload: reviewList.data.data
    });
  }
}
export default function* serviceSaga() {
  yield all([fork(watchService)]);
}
