import React from "react";
import { NavLink } from "react-router-dom";
import "./sidebar.scss";
const Sidebar = () => {
  return (
    <>
      <div className="left-sidebar">
        <ul id="myDIV">
          <li>
            <NavLink
              exact
              className="nav-link"
              activeClassName="active"
              to="/user-home"
            >
              HOME
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              to="/personal-info"
              className="nav-link"
              activeClassName="active"
            >
              Personal Info
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              to="/order-history"
              className="nav-link"
              activeClassName="active"
            >
              Order History
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              to="/payment-method"
              className="nav-link"
              activeClassName="active"
            >
              Payment Method
            </NavLink>
          </li>
          <li>
            <NavLink
              exact
              to="/security"
              className="nav-link"
              activeClassName="active"
            >
              Security
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};
export default Sidebar;
