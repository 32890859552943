import React, { useEffect, useCallback, useState } from "react";
import { Tab, Col, Nav, Row, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import InPerson from "./in-person/in-person";
import LiveOnline from "./live-online/live-online";
// import SummerPrep from "./summer-prep/summer-prep";

import "./schedules-tab.scss";
import { getCoursesBySubCategoryId } from "../../redux/action/Courses";

const SchedulesTab = props => {
  const { id, title } = props;
  const dispatch = useDispatch();
  const [activeKeyValue, setActiveKeyValue] = useState("first");
  const { courseList } = useSelector(state => {
    return state.courses;
  });
  useEffect(() => {
    setActiveKeyValue("first");
    dispatch(getCoursesBySubCategoryId({ subcategoryId: id }));
  }, [id, dispatch]);

  const handleClick = useCallback(
    (type, tag) => {
      if (!type && !tag) {
        dispatch(getCoursesBySubCategoryId({ subcategoryId: id }));
      } else {
        dispatch(
          getCoursesBySubCategoryId({
            subcategoryId: id,
            service_type: type,
            search: tag
          })
        );
      }
    },
    [id, dispatch]
  );

  return (
    <>
      <section className="schedules-tabs">
        <Tab.Container
          id="schedules-tab"
          activeKey={activeKeyValue}
          onSelect={setActiveKeyValue}
        >
          <Container>
            <h2 className="section-heading">{title} Schedules</h2>
            <Row className="justify-content-center">
              <Col lg={9}>
                <Nav className="flex-row justify-content-end upper-index">
                  <Nav.Item>
                    <Nav.Link eventKey="first" onClick={() => handleClick()}>
                      {title.indexOf("Private") !== -1
                        ? "All Options"
                        : "All Courses"}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="second"
                      onClick={() => handleClick("In Person", "")}
                    >
                      In Person
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="third"
                      onClick={() => handleClick("Live Online", "")}
                    >
                      Live Online
                    </Nav.Link>
                  </Nav.Item>
                  {title.indexOf("Private") === -1 ? (
                    <>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="fourth"
                          onClick={() => handleClick("", "summer prep")}
                        >
                          Summer Prep
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="fifth"
                          onClick={() => handleClick("", "Tests & Review")}
                        >
                          Tests & Review
                        </Nav.Link>
                      </Nav.Item>
                    </>
                  ) : null}
                </Nav>
              </Col>

              <Col lg={9}>
                <Tab.Content>
                  {courseList && courseList.length ? (
                    <>
                      <Tab.Pane eventKey="first">
                        <InPerson
                          courses={courseList.filter(
                            course => course.service_type === "In Person"
                          )}
                        />
                        <LiveOnline
                          courses={courseList.filter(
                            course => course.service_type === "Live Online"
                          )}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <InPerson
                          courses={courseList.filter(
                            course => course.service_type === "In Person"
                          )}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <LiveOnline
                          courses={courseList.filter(
                            course => course.service_type === "Live Online"
                          )}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="fourth">
                        <InPerson
                          tab="summer"
                          courses={courseList.filter(
                            course =>
                              course.tag === "Summer Prep" &&
                              course.service_type === "In Person"
                          )}
                        />
                        <LiveOnline
                          tab="summer"
                          courses={courseList.filter(
                            course =>
                              course.tag === "Summer Prep" &&
                              course.service_type === "Live Online"
                          )}
                        />
                      </Tab.Pane>
                      <Tab.Pane eventKey="fifth">
                        <InPerson
                          tab="summer"
                          courses={courseList.filter(
                            course =>
                              course.tag === "Tests & Review" &&
                              course.service_type === "In Person"
                          )}
                        />
                        <LiveOnline
                          tab="summer"
                          courses={courseList.filter(
                            course =>
                              course.tag === "Tests & Review" &&
                              course.service_type === "Live Online"
                          )}
                        />
                      </Tab.Pane>
                    </>
                  ) : null}
                </Tab.Content>
              </Col>
            </Row>
          </Container>
        </Tab.Container>
      </section>
    </>
  );
};
export default SchedulesTab;
