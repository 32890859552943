import React, { useState, useEffect, useRef } from "react";
import { Form, Col, Button, Row, Container } from "react-bootstrap";
import { useParams, useHistory, Link } from "react-router-dom";
import { Form as FinalForm, Field } from "react-final-form";
import { FORM_ERROR } from "final-form";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { initialValues } from "./formSetUp";
import { paymentInitials } from "./paymentFormSetup";
import { getCoursesList } from "../../redux/action/Courses";
import { changePaymentType } from "../../redux/action/Cart";
import {
  composeValidators,
  required,
  isValidEmail,
  mustBeNumber,
  digitValidate,
  digitccValidate,
  phoneValidate,
  phoneMaxLength,
} from "../../common/validationSchema";
import { registerUserAction } from "../../redux/action/Auth";
import { getStates } from "../../common/states";
import { getMonths, getYears } from "../../common/years";
import ProcessLoader from "../../components/ProcessLoader";
// import { getCart } from "../../redux/action/Cart";
import "./checkout.scss";

const Checkout = () => {
  const history = useHistory();
  let { id } = useParams();
  const dispatch = useDispatch();
  const [activeFirst, setActiveFirst] = useState("first");
  const [activeSecond, setActiveSecond] = useState();

  const { isLoggedIn, user } = useSelector((state) => state.auth);

  const { items: cartItems, type } = useSelector((state) => {
    return state.cart;
  });
  const { courseList } = useSelector((state) => {
    return state.courses;
  });
  const ids = cartItems.map((m) => m.courseId);
  const items = courseList.filter((m) => ids.includes(m._id));

  useEffect(() => {
    // if (!courseList.length || items.length !== cartItems.length) {
    //   dispatch(getCoursesList({ place: "" }));
    // }
    dispatch(getCoursesList({ place: "", count: 1000, page: 1 }));
  }, []);

  const handleActiveStatus = (e, first, second, item) => {
    if (item.payment_type === "Both") {
      setActiveFirst(first);
      setActiveSecond(second);
      let paytype = "";
      paytype = e.target.value;
      if (paytype !== "") {
        dispatch(changePaymentType({ paymentType: paytype }));
      } else {
        dispatch(changePaymentType({ paymentType: item.payment_type }));
      }
    } else {
      return true;
    }
  };

  const currencyFormat = (num) => {
    if (num)
      return (
        "$" +
        parseFloat(num)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
      );
    else {
      return "$ 0.00";
    }
  };

  let totalDays = 0;

  const calculateData = (values) => {
    values.paymentType =
      items[0].payment_type === "Both"
        ? type?.paymentType || "Full"
        : items[0].payment_type;

    values.length =
      items[0].emi_details.installment_details[0].paymentoptions_value;

    values.amount =
      items[0].payment_type === "Full" || type.paymentType === "Full"
        ? items[0].total
        : items.reduce(function (sum, current) {
            return sum + current.emi_details.due_today;
          }, 0);
    values.instalments =
      items[0].payment_type !== "Full"
        ? items[0].emi_details.noof_installments + 1
        : "";

    values.order.order_amount = items.reduce(function (sum, current) {
      return sum + current.subtotal;
    }, 0);

    values.order.discount = items.reduce(function (sum, current) {
      return sum + current.discount;
    }, 0);

    values.order.order_total = items.reduce(function (sum, current) {
      return sum + current.total;
    }, 0);

    const keys_to_keep = [
      "courseId",
      "subCategoryId",
      "categoryId",
      "price",
      "disc",
      "name",
    ];

    const redux = (array) =>
      array.map((o) =>
        keys_to_keep.reduce((acc, curr) => {
          o.courseId = o._id;
          o.subCategoryId = o.subcategoryId;
          o.price = o.total;
          o.disc = o.discount;
          o.name = o.subcategory_name;
          acc[curr] = o[curr];
          return acc;
        }, {})
      );
    values.orderDetails.courses = redux(items);
    return values;
  };
  const handleRegister = async (values, form) => {
    const valuestoMap = {
      ...values,
    };
    // const usersId = localStorage.getItem("user");
    valuestoMap.userId = user ? user._id : "";
    valuestoMap.expireMonth = valuestoMap.expireMonth
      ? valuestoMap.expireMonth
      : "01";
    valuestoMap.expireYear = valuestoMap.expireYear
      ? valuestoMap.expireYear
      : "2021";
    valuestoMap.expire =
      valuestoMap.expireMonth + valuestoMap.expireYear?.substring(2);
    delete valuestoMap.expireMonth;
    delete valuestoMap.expireYear;
    // values.expire = values.expire.replace(/[^\d]/g, "");
    const calculatedValues = calculateData(valuestoMap);

    if (!valuestoMap.paymentType) {
      return { [FORM_ERROR]: "Please select Payment Type" };
    }
    //window.alert(JSON.stringify(values, undefined, 2));
    dispatch(
      registerUserAction({ values: calculatedValues, history: history,form:form })
    );
  };

  return (
    <>
      <section className="cart-section">
        <Container>
          <Row>
            <Col md={12}>
              <div className="cart-step">
                <a href="#">
                  <div className="count">
                    <span> 1</span>
                    My Cart
                  </div>
                </a>
                <a href="#" className="active">
                  <div className="count">
                    <span> 2 </span>
                    Checkout
                  </div>
                </a>
              </div>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={6}>
              {id === "isSignUp" && !isLoggedIn ? (
                <div className="checkout-content">
                  <h3>CREATE AN ACCOUNT</h3>
                  <FinalForm
                    initialValues={initialValues}
                    onSubmit={(values, form) => handleRegister(values, form)}
                    render={({
                      submitError,
                      handleSubmit,
                      submitting,

                      values,
                    }) => {
                      return (
                        <form
                          onSubmit={(event) => {
                            event.preventDefault();
                            handleSubmit(event);
                          }}
                        >
                          <div className="radio-group">
                            <div className="one-line">
                              <span>Are you a</span>
                              <div className="form-check-inline">
                                <div className="form-check-label">
                                  <Field
                                    name="userDetails.account_type"
                                    className="form-check-input"
                                    id="parent"
                                    component="input"
                                    type="radio"
                                    value="parent"
                                    validate={required}
                                  />
                                  <label htmlFor="parent">Parent</label>
                                </div>
                              </div>
                              <span className="or">or</span>
                              <div className="form-check-inline">
                                <div className="form-check-label">
                                  <Field
                                    name="userDetails.account_type"
                                    className="form-check-input"
                                    id="student"
                                    component="input"
                                    type="radio"
                                    value="student"
                                    validate={required}
                                  />
                                  <label htmlFor="student">Student</label>
                                </div>
                              </div>
                              <strong>?</strong>
                            </div>
                          </div>

                          <div className="signin-info">
                            <Row>
                              <Form.Group>
                                <Field
                                  name="userDetails.email"
                                  type="email"
                                  placeholder=""
                                  validate={composeValidators(
                                    required,
                                    isValidEmail
                                  )}
                                >
                                  {({ input, meta }) => (
                                    <div>
                                      <Form.Control
                                        {...input}
                                        className={
                                          !input.value ? "invalid" : ""
                                        }
                                      />
                                      <Form.Label>Email Address</Form.Label>
                                      {meta.error && meta.touched && (
                                        <span className="error">
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </Form.Group>
                              <Form.Group>
                                <Field
                                  name="userDetails.password"
                                  type="password"
                                  placeholder=""
                                  validate={required}
                                >
                                  {({ input, meta }) => (
                                    <div>
                                      <Form.Control
                                        {...input}
                                        className={
                                          !input.value ? "invalid" : ""
                                        }
                                      />
                                      <Form.Label>Password</Form.Label>
                                      {meta.error && meta.touched && (
                                        <span className="error">
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </Form.Group>
                            </Row>
                            <Row>
                              <h3>STUDENT INFO</h3>
                              <Form.Group as={Col}>
                                <Field
                                  name="userDetails.firstName"
                                  type="text"
                                  placeholder=""
                                  validate={required}
                                >
                                  {({ input, meta }) => (
                                    <div>
                                      <Form.Control
                                        {...input}
                                        className={
                                          !input.value ? "invalid" : ""
                                        }
                                      />
                                      <Form.Label>
                                        Student's First Name
                                      </Form.Label>
                                      {meta.error && meta.touched && (
                                        <span className="error">
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </Form.Group>
                              <Form.Group as={Col}>
                                <Field
                                  name="userDetails.lastName"
                                  type="text"
                                  placeholder=""
                                  validate={required}
                                >
                                  {({ input, meta }) => (
                                    <div>
                                      <Form.Control
                                        {...input}
                                        className={
                                          !input.value ? "invalid" : ""
                                        }
                                      />
                                      <Form.Label>
                                        Student's Last Name
                                      </Form.Label>
                                      {meta.error && meta.touched && (
                                        <span className="error">
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </Form.Group>
                              <Form.Group>
                                <Field
                                  name="userDetails.student_email"
                                  type="email"
                                  placeholder=""
                                  validate={composeValidators(
                                    required,
                                    isValidEmail
                                  )}
                                >
                                  {({ input, meta }) => (
                                    <div>
                                      <Form.Control
                                        {...input}
                                        className={
                                          !input.value ? "invalid" : ""
                                        }
                                      />
                                      <Form.Label>
                                        Student's Email Address
                                      </Form.Label>
                                      {meta.error && meta.touched && (
                                        <span className="error">
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </Form.Group>
                            </Row>

                            <Row>
                              <h3>PARENT INFO</h3>
                              <Form.Group as={Col}>
                                <Field
                                  name="userDetails.parent_details.firstName"
                                  type="text"
                                  placeholder=""
                                  validate={required}
                                >
                                  {({ input, meta }) => (
                                    <div>
                                      <Form.Control
                                        {...input}
                                        className={
                                          !input.value ? "invalid" : ""
                                        }
                                      />
                                      <Form.Label>
                                        Parent's First Name
                                      </Form.Label>
                                      {meta.error && meta.touched && (
                                        <span className="error">
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </Form.Group>
                              <Form.Group as={Col}>
                                <Field
                                  name="userDetails.parent_details.lastName"
                                  type="text"
                                  placeholder=""
                                  validate={required}
                                >
                                  {({ input, meta }) => (
                                    <div>
                                      <Form.Control
                                        {...input}
                                        className={
                                          !input.value ? "invalid" : ""
                                        }
                                      />
                                      <Form.Label>
                                        Parent's Last Name
                                      </Form.Label>
                                      {meta.error && meta.touched && (
                                        <span className="error">
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </Form.Group>
                            </Row>
                            <Form.Group>
                              <Field
                                name="userDetails.parent_details.email"
                                type="email"
                                placeholder=""
                                validate={composeValidators(
                                  required,
                                  isValidEmail
                                )}
                              >
                                {({ input, meta }) => (
                                  <div>
                                    <Form.Control
                                      {...input}
                                      className={!input.value ? "invalid" : ""}
                                    />
                                    <Form.Label>
                                      Parent's Email Address
                                    </Form.Label>
                                    {meta.error && meta.touched && (
                                      <span className="error">
                                        {meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </Form.Group>
                            <Row>
                              <Form.Group as={Col}>
                                <Field
                                  name="userDetails.parent_details.phone"
                                  type="text"
                                  placeholder=""
                                  parse={phoneValidate}
                                  validate={composeValidators(
                                    required,
                                    phoneMaxLength
                                  )}
                                >
                                  {({ input, meta }) => (
                                    <div>
                                      <Form.Control
                                        {...input}
                                        maxLength="10"
                                        className={
                                          !input.value ? "invalid" : ""
                                        }
                                      />
                                      <Form.Label>Phone Number</Form.Label>
                                      {meta.error && meta.touched && (
                                        <span className="error">
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </Form.Group>
                              <Form.Group as={Col}>
                                <Field
                                  name="userDetails.parent_details.address.street"
                                  type="text"
                                  placeholder=""
                                  validate={required}
                                >
                                  {({ input, meta }) => (
                                    <div>
                                      <Form.Control
                                        {...input}
                                        className={
                                          !input.value ? "invalid" : ""
                                        }
                                      />
                                      <Form.Label>Street Address</Form.Label>
                                      {meta.error && meta.touched && (
                                        <span className="error">
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </Form.Group>
                            </Row>

                            <Row>
                              <Form.Group as={Col}>
                                <Field
                                  name="userDetails.parent_details.address.apartment"
                                  type="text"
                                  placeholder=""
                                >
                                  {({ input, meta }) => (
                                    <div>
                                      <Form.Control
                                        {...input}
                                        className={
                                          !input.value ? "invalid" : ""
                                        }
                                      />
                                      <Form.Label>
                                        Apartment, unit, suite, or floor #
                                      </Form.Label>
                                      {meta.error && meta.touched && (
                                        <span className="error">
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </Form.Group>
                              <Form.Group as={Col}>
                                <Field
                                  name="userDetails.parent_details.address.city"
                                  type="text"
                                  placeholder=""
                                  validate={required}
                                >
                                  {({ input, meta }) => (
                                    <div>
                                      <Form.Control
                                        {...input}
                                        className={
                                          !input.value ? "invalid" : ""
                                        }
                                      />
                                      <Form.Label>City</Form.Label>
                                      {meta.error && meta.touched && (
                                        <span className="error">
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </Form.Group>
                            </Row>
                            <Row>
                              <Form.Group as={Col}>
                                <Form.Label>State</Form.Label>
                                <Field
                                  name="userDetails.parent_details.address.state"
                                  component="select"
                                  placeholder=""
                                  validate={required}
                                >
                                  {getStates().statesArray.map((_state, i) => (
                                    <option key={`opt${i}`} value={_state.name}>
                                      {_state.name}
                                    </option>
                                  ))}
                                </Field>
                              </Form.Group>
                              {/* <Form.Group as={Col}>
									<Field
									  name="userDetails.parent_details.address.state"
									  type="text"
									  placeholder=""
									  validate={required}
									>
									  {({ input, meta }) => (
										<div>
										  <Form.Control {...input} required />
										  <Form.Label>State</Form.Label>
										  {meta.error && meta.touched && (
											<span className="error">
											  {meta.error}
											</span>
										  )}
										</div>
									  )}
									</Field>
								  </Form.Group>
								   */}
                              <Form.Group as={Col}>
                                <Field
                                  name="userDetails.parent_details.address.zipcode"
                                  type="text"
                                  placeholder=""
                                  validate={composeValidators(
                                    required,
                                    mustBeNumber
                                  )}
                                >
                                  {({ input, meta }) => (
                                    <div>
                                      <Form.Control
                                        {...input}
                                        className={
                                          !input.value ? "invalid" : ""
                                        }
                                      />
                                      <Form.Label>Zipcode</Form.Label>
                                      {meta.error && meta.touched && (
                                        <span className="error">
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </Form.Group>
                            </Row>

                            <Row>
                              <div className="secure-payment">
                                <h3>PAYMENT METHOD</h3>
                                <p>
                                  <img
                                    src="/images/shield.svg"
                                    className="img-fluid"
                                    alt="Shield"
                                  />
                                  Secure Payment
                                </p>
                              </div>
                              <Col>
                                <h4>Pay with Credit/Debit Card</h4>
                              </Col>
                              <Form.Group>
                                <Field
                                  name="cc"
                                  type="text"
                                  placeholder=""
                                  validate={composeValidators(
                                    required,
                                    mustBeNumber,
                                    digitccValidate
                                  )}
                                >
                                  {({ input, meta }) => (
                                    <div>
                                      <Form.Control
                                        {...input}
                                        className={
                                          !input.value && meta.error
                                            ? "invalid"
                                            : ""
                                        }
                                      />
                                      <Form.Label>Card Number</Form.Label>
                                      {meta.error && meta.touched && (
                                        <span className="error">
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </Form.Group>
                            </Row>
                            <Row>
                              <Form.Group as={Col}>
                                <Field
                                  name="cardfirstName"
                                  type="text"
                                  placeholder=""
                                  validate={required}
                                >
                                  {({ input, meta }) => (
                                    <div>
                                      <Form.Control
                                        {...input}
                                        className={
                                          !input.value && meta.error
                                            ? "invalid"
                                            : ""
                                        }
                                      />
                                      <Form.Label>
                                        Card Holder First Name
                                      </Form.Label>
                                      {meta.error && meta.touched && (
                                        <span className="error">
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </Form.Group>
                              <Form.Group as={Col}>
                                <Field
                                  name="cardlastName"
                                  type="text"
                                  placeholder=""
                                  validate={required}
                                >
                                  {({ input, meta }) => (
                                    <div>
                                      <Form.Control
                                        {...input}
                                        className={
                                          !input.value && meta.error
                                            ? "invalid"
                                            : ""
                                        }
                                      />
                                      <Form.Label>
                                        Card Holder Last Name
                                      </Form.Label>
                                      {meta.error && meta.touched && (
                                        <span className="error">
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </Form.Group>
                            </Row>
                            <Row>
                              <Form.Group as={Col}>
                                <Form.Label>Expire Month</Form.Label>
                                <Field
                                  name="expireMonth"
                                  component="select"
                                  placeholder=""
                                  validate={required}
                                >
                                  {getMonths().monthArray.map((_month, i) => (
                                    <option
                                      key={`opt${i}`}
                                      value={_month.value}
                                    >
                                      {_month.name}
                                    </option>
                                  ))}
                                </Field>
                              </Form.Group>
                              <Form.Group as={Col}>
                                <Form.Label>Expire Year</Form.Label>
                                <Field
                                  name="expireYear"
                                  component="select"
                                  placeholder=""
                                  validate={required}
                                >
                                  {getYears().map((_month, i) => (
                                    <option key={`opt${i}`} value={_month}>
                                      {_month}
                                    </option>
                                  ))}
                                </Field>
                              </Form.Group>
                            </Row>
                            <Row>
                              <Form.Group as={Col}>
                                <Field
                                  name="cvv"
                                  type="password"
                                  placeholder=""
                                  validate={composeValidators(
                                    required,
                                    mustBeNumber,
                                    digitValidate
                                  )}
                                >
                                  {({ input, meta }) => (
                                    <div>
                                      <Form.Control
                                        {...input}
                                        className={
                                          !input.value ? "invalid" : ""
                                        }
                                      />
                                      <Form.Label>Security Code</Form.Label>
                                      {meta.error && meta.touched && (
                                        <span className="error">
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </Form.Group>
                              <Form.Group as={Col}>
                                <Field
                                  name="cardzip"
                                  type="text"
                                  placeholder=""
                                  validate={composeValidators(
                                    required,
                                    mustBeNumber
                                  )}
                                >
                                  {({ input, meta }) => (
                                    <div>
                                      <Form.Control
                                        {...input}
                                        className={
                                          !input.value ? "invalid" : ""
                                        }
                                      />
                                      <Form.Label>Zipcode</Form.Label>
                                      {meta.error && meta.touched && (
                                        <span className="error">
                                          {meta.error}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </Field>
                              </Form.Group>
                            </Row>

                            <Row>
                              <p>
                                I accept{" "}
                                {/* <Link to="/terms-and-conditions"> */}
                                <a target="_blank" href="/terms-and-conditions">
                                  Bobby-Tariq Tutoring Center's Terms and
                                  Conditions
                                  {/* </Link> */}
                                </a>
                              </p>
                            </Row>

                            <Button
                              type="submit"
                              className="btn"
                              disabled={submitting}
                              style={{ display: "flex" }}
                            >
                              {
                                <img
                                  src="/images/lock-payment.svg"
                                  className="img-fluid"
                                  alt="icon"
                                />
                              }
                              {submitting ? (
                                <ProcessLoader isVisible={submitting} />
                              ) : (
                                "Confirm Payment"
                              )}
                            </Button>
                          </div>
                          {submitError && (
                            <div className="error">{submitError}</div>
                          )}
                        </form>
                      );
                    }}
                  />
                </div>
              ) : (
                <div className="checkout-content">
                  <FinalForm
                    initialValues={paymentInitials}
                    onSubmit={handleRegister}
                    render={({
                      submitError,
                      handleSubmit,
                      reset,
                      submitting,
                      pristine,
                      values,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="signin-info">
                          <Row>
                            <div className="secure-payment">
                              <h3>Payment Method</h3>
                              <p>
                                <img
                                  src="/images/shield.svg"
                                  className="img-fluid"
                                  alt="Shield"
                                />
                                Secure Payment
                              </p>
                            </div>
                            <Col>
                              <h4>Pay with Credit/Debit Card</h4>
                            </Col>
                            <Form.Group>
                              <Field
                                name="cc"
                                type="text"
                                placeholder=""
                                validate={composeValidators(
                                  required,
                                  mustBeNumber,
                                  digitccValidate
                                )}
                              >
                                {({ input, meta }) => (
                                  <div>
                                    <Form.Control
                                      {...input}
                                      className={
                                        !input.value && meta.error
                                          ? "invalid"
                                          : ""
                                      }
                                    />
                                    <Form.Label>Card Number</Form.Label>
                                    {meta.error && meta.touched && (
                                      <span className="error">
                                        {meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </Form.Group>
                          </Row>
                          <Row>
                            <Form.Group as={Col}>
                              <Field
                                name="cardfirstName"
                                type="text"
                                placeholder=""
                                validate={required}
                              >
                                {({ input, meta }) => (
                                  <div>
                                    <Form.Control
                                      {...input}
                                      className={
                                        !input.value && meta.error
                                          ? "invalid"
                                          : ""
                                      }
                                    />
                                    <Form.Label>
                                      Card Holder First Name
                                    </Form.Label>
                                    {meta.error && meta.touched && (
                                      <span className="error">
                                        {meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </Form.Group>
                            <Form.Group as={Col}>
                              <Field
                                name="cardlastName"
                                type="text"
                                placeholder=""
                                validate={required}
                              >
                                {({ input, meta }) => (
                                  <div>
                                    <Form.Control
                                      {...input}
                                      className={
                                        !input.value && meta.error
                                          ? "invalid"
                                          : ""
                                      }
                                    />
                                    <Form.Label>
                                      Card Holder Last Name
                                    </Form.Label>
                                    {meta.error && meta.touched && (
                                      <span className="error">
                                        {meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </Form.Group>
                          </Row>
                          <Row>
                            <Form.Group as={Col}>
                              <Form.Label>Expire Month</Form.Label>
                              <Field
                                name="expireMonth"
                                component="select"
                                placeholder=""
                                validate={required}
                              >
                                {getMonths().monthArray.map((_month, i) => (
                                  <option key={`opt${i}`} value={_month.value}>
                                    {_month.name}
                                  </option>
                                ))}
                              </Field>
                            </Form.Group>
                            <Form.Group as={Col}>
                              <Form.Label>Expire Year</Form.Label>
                              <Field
                                name="expireYear"
                                component="select"
                                placeholder=""
                                validate={required}
                              >
                                {getYears().map((_month, i) => (
                                  <option key={`opt${i}`} value={_month}>
                                    {_month}
                                  </option>
                                ))}
                              </Field>
                            </Form.Group>
                          </Row>
                          <Row>
                            <Form.Group as={Col}>
                              <Field
                                name="cvv"
                                type="password"
                                placeholder=""
                                validate={composeValidators(
                                  required,
                                  mustBeNumber,
                                  digitValidate
                                )}
                              >
                                {({ input, meta }) => (
                                  <div>
                                    <Form.Control
                                      {...input}
                                      className={!input.value ? "invalid" : ""}
                                    />
                                    <Form.Label>Security Code</Form.Label>
                                    {meta.error && meta.touched && (
                                      <span className="error">
                                        {meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </Form.Group>
                            <Form.Group as={Col}>
                              <Field
                                name="cardzip"
                                type="text"
                                placeholder=""
                                validate={composeValidators(
                                  required,
                                  mustBeNumber
                                )}
                              >
                                {({ input, meta }) => (
                                  <div>
                                    <Form.Control
                                      {...input}
                                      className={!input.value ? "invalid" : ""}
                                    />
                                    <Form.Label>Zipcode</Form.Label>
                                    {meta.error && meta.touched && (
                                      <span className="error">
                                        {meta.error}
                                      </span>
                                    )}
                                  </div>
                                )}
                              </Field>
                            </Form.Group>
                          </Row>

                          <Button
                            type="submit"
                            className="btn"
                            disabled={submitting}
                          >
                            {
                              <img
                                src="/images/lock-payment.svg"
                                className="img-fluid"
                                alt="icon"
                              />
                            }
                            {submitting ? (
                              <ProcessLoader isVisible={submitting} />
                            ) : (
                              "Confirm Payment"
                            )}
                          </Button>
                        </div>
                        {submitError && (
                          <div className="error">{submitError}</div>
                        )}
                      </form>
                    )}
                  />
                </div>
              )}
            </Col>
            <Col md={6}>
              <div className="payment-outer">
                <div className="order-summery">
                  <h3>Order Summary</h3>

                  {items?.length
                    ? items.map((item, index) => {
                        const isPrivate =
                          item.subcategory_name.indexOf("Private") !== -1;

                        return (
                          <>
                            <div className="three-points" key={`item_${index}`}>
                              <div className="left">
                                <p>
                                  {item.subcategory_name} - {item.service_type}{" "}
                                  <br />
                                </p>
                                {!item.only_description ? (
                                  <span>
                                    {isPrivate ? (
                                      <p>{item.noof_hours} Hours</p>
                                    ) : (
                                      `${moment(item.start_date).format(
                                        "MMM D"
                                      )} -
                                  ${moment(item.end_date).format("MMM D YYYY")}`
                                    )}
                                    <br />

                                    {isPrivate
                                      ? ""
                                      : `${item.days[0].day}-
                                  ${item.days[item.days.length - 1].day} 
                                  (${item.noof_classes} Classes)`}

                                    {isPrivate ? (
                                      <div
                                        className="timeline"
                                        dangerouslySetInnerHTML={{
                                          __html: item.description,
                                        }}
                                      ></div>
                                    ) : (
                                      <>
                                        {" "}
                                        <br />
                                        {item.start_time} - {item.end_time}
                                        <br />
                                        {item.location} <br />
                                        <br />
                                        <div
                                          className="timeline"
                                          dangerouslySetInnerHTML={{
                                            __html: item.description,
                                          }}
                                        ></div>
                                      </>
                                    )}
                                  </span>
                                ) : (
                                  <>
                                    <br />
                                    {item.only_desc_showdate && (
                                      <p>
                                        {moment(item.start_date).format(
                                          "MMM D"
                                        )}{" "}
                                        -
                                        {moment(item.end_date).format(
                                          "MMM D YYYY"
                                        )}
                                      </p>
                                    )}
                                    <div
                                      className="timeline"
                                      dangerouslySetInnerHTML={{
                                        __html: item.description,
                                      }}
                                    ></div>
                                  </>
                                )}
                              </div>
                              <div className="right">
                                <span>{`${
                                  item.discount === 0
                                    ? ""
                                    : currencyFormat(item.subtotal)
                                }`}</span>
                                <p>{currencyFormat(item.total)}</p>
                              </div>
                            </div>
                            {item.discount !== 0 ? (
                              <div className="cupon">
                                <span>
                                  <strong>
                                    <input
                                      type="text"
                                      placeholder="applyhere"
                                      value={`Save${currencyFormat(
                                        item.discount
                                      )}`}
                                      readOnly
                                    />
                                  </strong>
                                </span>
                                <button type="submit" className="apply-btn">
                                  Applied!
                                </button>
                              </div>
                            ) : null}

                            <div className="select-payment">
                              <h6>Select Payment Option</h6>
                              <div className="payment-option">
                                {/* <a
                                  className={`oneoff ${
                                    (activeFirst &&
                                      (item.payment_type === "Full" ||
                                        type?.paymentType === "Full")) ||
                                    item.payment_type === "Both"
                                      ? "active"
                                      : ""
                                  }`}
                                > */}
                                <a
                                  className={`oneoff ${
                                    activeFirst &&
                                    (item.payment_type === "Full" ||
                                      item.payment_type === "Both") &&
                                    type?.paymentType !== "Installment"
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  <input
                                    type="radio"
                                    name="payment_type"
                                    defaultValue="Full"
                                    onChange={(e) =>
                                      handleActiveStatus(e, "first", "", item)
                                    }
                                  />
                                  <small>
                                    <strong>Pay In Full</strong>
                                  </small>
                                  <small className="mt-1 mb-3">Due Today</small>
                                  <span>{currencyFormat(item.total)}</span>
                                </a>
                                <div className="or-cricle">or</div>

                                <a
                                  className={`oneoff ${
                                    activeSecond ||
                                    item.payment_type === "Installment" ||
                                    type?.paymentType === "Installment"
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  <input
                                    type="radio"
                                    name="payment_type"
                                    defaultValue="Installment"
                                    onChange={(e) =>
                                      handleActiveStatus(e, "", "second", item)
                                    }
                                  />
                                  <small>
                                    <strong>Pay In Installments</strong>
                                  </small>
                                  {item.payment_type !== "Full" ? (
                                    <>
                                      <p>
                                        Due Today
                                        <span>
                                          {currencyFormat(
                                            item.emi_details.due_today
                                          )}
                                        </span>
                                      </p>
                                      {item.emi_details.payment_option ===
                                      "Option 1"
                                        ? item.emi_details.installment_details.map(
                                            (_date, i) => {
                                              totalDays =
                                                totalDays +
                                                parseInt(
                                                  _date.paymentoptions_value
                                                );
                                              return (
                                                <p key={`emi_${i}`}>
                                                  {moment()
                                                    .add(totalDays, "days")
                                                    .format("MM/DD/YYYY")}
                                                  <span>
                                                    {currencyFormat(
                                                      _date.value
                                                    )}
                                                  </span>
                                                </p>
                                              );
                                            }
                                          )
                                        : item.emi_details.payment_option ===
                                          "Option 2"
                                        ? item.emi_details.installment_details.map(
                                            (_date, i) => (
                                              <>
                                                <p key={`emi_${i}`}>
                                                  {moment(
                                                    _date.paymentoptions_value
                                                  ).format("MM/DD/YYYY")}
                                                  <span>
                                                    {currencyFormat(
                                                      _date.value
                                                    )}
                                                  </span>
                                                </p>
                                              </>
                                            )
                                          )
                                        : item.emi_details.installment_details.map(
                                            (_date, i) => (
                                              <p key={`emi_${i}`}>
                                                {/* {_date.paymentoptions_value} */}
                                                {moment()
                                                  .add(
                                                    _date.paymentoptions_value,
                                                    "days"
                                                  )
                                                  .format("MM/DD/YYYY")}
                                                <span>
                                                  {currencyFormat(_date.value)}
                                                </span>
                                              </p>
                                            )
                                          )}
                                    </>
                                  ) : null}
                                </a>
                              </div>
                            </div>
                          </>
                        );
                      })
                    : null}
                  <div className="total-payment">
                    <p>
                      Subtotal
                      <span>
                        {items.reduce(function (sum, current) {
                          const subFormat = sum + current.subtotal;
                          return currencyFormat(subFormat);
                        }, 0)}
                      </span>
                    </p>
                    <p>
                      Discount
                      <span>
                        -
                        {items.reduce(function (sum, current) {
                          const discountFormat = sum + current.discount;
                          return currencyFormat(discountFormat);
                        }, 0)}
                      </span>
                    </p>
                    <p>
                      Total
                      <span>
                        {items.reduce(function (sum, current) {
                          const totalFormat = sum + current.total;
                          return currencyFormat(totalFormat);
                        }, 0)}
                      </span>
                    </p>
                  </div>
                  <div className="final-amount">
                    <p>
                      PAY TODAY
                      <span>
                        {`${
                          type?.paymentType === "Installment" ||
                          items[0]?.payment_type === "Installment"
                            ? currencyFormat(items[0]?.emi_details?.due_today)
                            : currencyFormat(items[0]?.total)
                        } USD`}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Checkout;
