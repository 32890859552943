import moment from "moment";

export const getDaysBetweenDates = (start, end, daysInfo) => {
  var result = [];

  let arr = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  if (daysInfo.some(info => info["day"] !== arr[start.getDay()])) {
    result.push({ date: new Date(+start) });
  }
  if (daysInfo.some(info => info["day"] !== arr[end.getDay()])) {
    result.push({ date: new Date(+end) });
  }

  var days = { sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6 };
  daysInfo.forEach((_day, index) => {
    var day = days[_day.day.toLowerCase().substr(0, 5)];
    var current = new Date(start);

    current.setDate(current.getDate() + ((day - current.getDay() + 7) % 7));

    while (current <= end) {
      result.push({ date: new Date(+current) });
      current.setDate(current.getDate() + 7);
    }
  });

  var unique = (src, fn) =>
    src.filter((s => o => !s.has(fn(o)) && s.add(fn(o)))(new Set()));

  return unique(result, ({ date }) => date.toISOString().slice(0, 10)).sort(
    (a, b) => {
      return moment(a.date).diff(b.date);
    }
  );
};

export const map = {
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6,
  Sun: 0
};
