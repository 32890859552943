import { BLOG_LIST_REQUEST, BLOG_REQUEST } from "../constants";
export const getBlogs = params => {
  return {
    type: BLOG_LIST_REQUEST,
    payload: params
  };
};
export const getBlog = params => {
  return {
    type: BLOG_REQUEST,
    params
  };
};
