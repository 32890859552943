import React, { useEffect, useState, useCallback, useRef } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { Form, Col, Button, Row, Container, Toast } from "react-bootstrap";
import { Form as FinalForm, Field } from "react-final-form";
import { useSelector, useDispatch } from "react-redux";
import { CustomMultiSelect } from "../../components/customInput/custom";
import { FORM_ERROR } from "final-form";
import axios from "axios";

import { getSubCategoryList } from "../../redux/action/SubCategories";
import {
  composeValidators,
  required,
  isValidEmail,
  arrayRequired,
} from "../../common/validationSchema";
import "./contact-us.scss";
import SEO from "../../components/seo";
import { baseUrl } from "../../ennviroment";

const ContactUs = () => {
  const dispatch = useDispatch();
  const [services, setServices] = useState([]);
  const [sales, setSales] = useState("");
  const [office, setOffice] = useState("");

  const [showToast, setShowToast] = useState(false);
  const [metaTags, setMetaTags] = useState({});
  const [responseMessage, setresponseMessage] = useState("");
  const multiselectRef = useRef();

  const { subCategoryList } = useSelector((state) => {
    return state.subcategories;
  });

  useEffect(() => {
    dispatch(getSubCategoryList());
  }, []);

  useEffect(() => {
    setServices(subCategoryList.data);
  }, [subCategoryList]);

  useEffect(() => {
    axios
      .get(`${baseUrl}api/v1/page/get-page?slug=contact-us-contacts`)
      .then((res) => {
        setSales(res.data.data.data[0].description);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${baseUrl}api/v1/page/get-page?slug=contact-us-offices`)
      .then((res) => {
        setMetaTags(res.data.data.data[0]);
        setOffice(res.data.data.data[0].description);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (responseMessage.length === 0) return;
    setShowToast(!showToast);
  }, [responseMessage]);

  const handleLogin = useCallback(async (values, form) => {
    try {
      if (!values.services.length) {
        return { [FORM_ERROR]: "Please select atleast one Service" };
      }
      values.services = values.services.map((_service) => {
        return _service.title;
      });
      multiselectRef.current.resetSelectedValues();
      axios
        .post(`${baseUrl}api/v1/question/ask-question`, values)
        .then(async (res) => {
          toast.success(res.data.message);
        })
        .catch(async (err) => {
          toast.error(err.message);
        });

      setTimeout(() => {
        Object.keys(values).forEach((key) => {
          form.resetFieldState(key);
        });
        form.reset();
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleScroll = useCallback((id) => () => {
    const elment = document.getElementById(id);
    elment.scrollIntoView({ behavior: "smooth" });
  });

  return (
    <>
      {/* <div
        aria-live="polite"
        aria-atomic="true"
        style={{
          position: "relative",
          minHeight: "100px"
        }}
      > */}
      <Toast
        style={{
          position: "fixed",
          top: 10,
          right: 0,
          zIndex: 9,
        }}
        show={showToast}
        onClose={() => setShowToast(!showToast)}
        delay={3000}
        autohide
      >
        <Toast.Header>
          <strong className="mrauto">{responseMessage}</strong>
        </Toast.Header>
      </Toast>
      {/* </div> */}
      {metaTags && Object.keys(metaTags).length ? (
        <SEO
          title={metaTags.meta_title}
          description={metaTags.meta_description}
          keywords={metaTags.meta_keywords}
        />
      ) : null}
      <section className="main-banner contactus">
        <Container>
          <Row className="align-items-center">
            <Col md={1}></Col>
            <Col md={4} col lg={4} className="p-0">
              <h2>Contact Us</h2>
              <p>
                Want to get in touch? We'd love to hear from you. You can call,
                email, or visit our locations.
              </p>
            </Col>
            <Col md={1} col lg={1}></Col>
            <Col md={6}>
              <div className="prep-outer mx-auto text-center">
                <div className="boxes" onClick={handleScroll("sales")}>
                  <h5>
                    <button>Contact Sales or Support</button>
                  </h5>
                </div>
                <div className="boxes" onClick={handleScroll("location")}>
                  <h5>
                    <button>Locations</button>
                  </h5>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="contact-us-section mb-4">
        <Container>
          <Row>
            <Col className="custom-flex " id="location" md={6}>
              {/* <div
                dangerouslySetInnerHTML={{
                  __html: sales
                }}
              ></div> */}
              <div
                dangerouslySetInnerHTML={{
                  __html: office,
                }}
              ></div>
            </Col>
            <Col md={6} id="sales" className="contact-form-container">
              <div className="contact-outer mail-box">
                <div className="call-to">
                  <div className="icon-outer">
                    <img
                      src="/images/email-icon.svg"
                      className="img-fluid"
                      alt="call"
                    />
                  </div>
                  <div className="inside-cont">
                    <h5>Have questions? Email us</h5>
                    <a href="mailto:service@bobbytariq.com">
                      service@bobbytariq.com
                    </a>
                  </div>
                </div>
                <div className="contact-form">
                  <FinalForm
                    initialValues={{
                      firstName: "",
                      lastName: "",
                      email: "",
                      phone: "",
                      services: [],
                      message: "",
                    }}
                    onSubmit={handleLogin}
                    render={({
                      handleSubmit,
                      submitting,
                      submitError,
                      form,
                      reset,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Row>
                          <Form.Group as={Col} className="box">
                            <Field
                              name="firstName"
                              type="text"
                              validate={required}
                            >
                              {({ input, meta }) => (
                                <>
                                  <Form.Label>First Name</Form.Label>
                                  <Form.Control {...input} placeholder="" />
                                  {meta.error && meta.touched && (
                                    <span className="error">{meta.error}</span>
                                  )}
                                </>
                              )}
                            </Field>
                          </Form.Group>
                          <Form.Group as={Col} className="box">
                            <Field
                              name="lastName"
                              type="text"
                              validate={required}
                            >
                              {({ input, meta }) => (
                                <>
                                  <Form.Label>Last Name</Form.Label>
                                  <Form.Control {...input} placeholder="" />
                                  {meta.error && meta.touched && (
                                    <span className="error">{meta.error}</span>
                                  )}
                                </>
                              )}
                            </Field>
                          </Form.Group>
                        </Row>
                        <Row>
                          <Form.Group as={Col} className="box">
                            <Field
                              name="email"
                              type="email"
                              validate={composeValidators(
                                required,
                                isValidEmail
                              )}
                            >
                              {({ input, meta }) => (
                                <>
                                  <Form.Label>Email Address</Form.Label>
                                  <Form.Control {...input} placeholder="" />
                                  {meta.error && meta.touched && (
                                    <span className="error">{meta.error}</span>
                                  )}
                                </>
                              )}
                            </Field>
                          </Form.Group>
                          <Form.Group as={Col} className="box">
                            <Field name="phone" type="tel" validate={required}>
                              {({ input, meta }) => (
                                <>
                                  <Form.Label>Your Phone Number</Form.Label>
                                  <Form.Control {...input} placeholder="" />
                                  {meta.error && meta.touched && (
                                    <span className="error">{meta.error}</span>
                                  )}
                                </>
                              )}
                            </Field>
                          </Form.Group>
                        </Row>

                        <Row>
                          <Form.Group as={Col} className="box">
                            <CustomMultiSelect
                              name="services"
                              validate={arrayRequired}
                              options={services}
                              multiselectRef={multiselectRef}
                            />
                            {submitError && (
                              <div className="error">{submitError}</div>
                            )}
                          </Form.Group>
                        </Row>
                        <Row>
                          <Form.Group as={Col} className="box">
                            <Field
                              name="message"
                              type="text"
                              validate={required}
                            >
                              {({ input, meta }) => (
                                <>
                                  <Form.Label>Message</Form.Label>
                                  <textarea
                                    className="form-control"
                                    {...input}
                                    placeholder=""
                                  ></textarea>
                                  {meta.error && meta.touched && (
                                    <span className="error">{meta.error}</span>
                                  )}
                                </>
                              )}
                            </Field>
                          </Form.Group>
                        </Row>
                        <Button
                          variant="success"
                          type="button"
                          type="submit"
                          disabled={submitting}
                        >
                          {" "}
                          Submit
                        </Button>
                        {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                      </form>
                    )}
                  />

                  {/* <Form>
                    <Row>
                      <Form.Group>
                        <Form.Label>Select Service</Form.Label>
                        <Multiselect
                          options={services}
                          onSelect={onSelect}
                          onRemove={onSelect}
                          displayValue="title"
                          style={{
                            chips: { background: "#5ec19b" },
                            option: { background: "#5ec19b", color: "#ffffff" }
                          }}
                        />
                      </Form.Group>
                    </Row>

                    <Button variant="success" type="button">
                      {" "}
                      Submit
                    </Button>
                  </Form>
                   */}
                  {/* {showToast && ( */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* <section className="officer-section" id="loc">
        <Container>
          <Row>
            <Col sm={12}>
              <div
                dangerouslySetInnerHTML={{
                  __html: office
                }}
              ></div>
            </Col>
          </Row>
        </Container>
      </section> */}

      <section className="green-search">
        <Container>
          <Row>
            <Col sm={12}>
              <div className=" green-container">
                <a href="#" className="contact-us-btn">
                  Contact Us
                </a>
                {/* <h1 className="watermark-text">Contact Us</h1> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default ContactUs;

// onSubmit={async event => {
//               await handleSubmit(event)
//               form.reset()
//             }}
