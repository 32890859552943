import {
  SERVICE_REQUEST,
  SERVICE_SUCCESS,
  FAQ_REQUEST,
  FAQ_SUCCESS,
  REVIEW_REQUEST,
  REVIEW_SUCCESS
} from "../constants";
const initialState = {
  isLoading: false,
  service: [],
  faqList: []
};
const ServiceReducer = (
  state = initialState,
  { type = null, payload = null } = {}
) => {
  switch (type) {
    case SERVICE_REQUEST:
      return {
        ...state,
        isLoading: true,
        id: payload.id
      };
    case SERVICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        service: payload
      };
    case FAQ_REQUEST:
      return {
        ...state,
        isLoading: true,
        id: payload.id
      };
    case FAQ_SUCCESS:
      return {
        ...state,
        isLoading: false,
        faqList: payload
      };
    case REVIEW_REQUEST:
      return {
        ...state,
        isLoading: true
      };
    case REVIEW_SUCCESS:
      return {
        ...state,
        isLoading: false,
        reviewList: payload
      };
    default:
      return state;
  }
};
export default ServiceReducer;
