import React from "react";
import { Accordion, Card, Col, Row, Container } from "react-bootstrap";
import "./faq-accordion.scss";
const FaqAccordion = props => {
  const { list } = props;
  return (
    <>
      <section className="faq-accordion">
        <Container>
          <Row>
            <Col md={12}>
              <h2 className="section-heading">Frequently Asked Questions</h2>
              <Accordion>
                {list.map((el, index) => (
                  <Card key={`card_${index}`}>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey={`event${index}`}
                    >
                      Q: {el.question}
                      <span>
                        <img src="/images/down-chevron.svg" alt="Icon" />
                      </span>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={`event${index}`}>
                      <Card.Body>{el.answer}</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default FaqAccordion;
