import React, { useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import "./terms-conditions.scss";
import axios from "axios";
import { baseUrl } from "../../ennviroment";

const TermsConditions = () => {
  const [data, setData] = useState("");
  useEffect(() => {
    axios
      .get(
        `${baseUrl}api/v1/page/get-page?slug=tearms-and-conditions`
      )
      .then(res => {
        const {
          data: {
            data: { data }
          }
        } = res;
        setData(data[0]);
      })
      .catch(err => console.log(err));
  }, []);
  function createMarkup() {
    return { __html: `${data?.description}` };
  }

  return (
    <>
      <section className="terms-conditions">
        <Container>
          <Row className="justify-content-center mt-5 mb-5">
            <Col md={9}>
              {data && <div dangerouslySetInnerHTML={createMarkup()}></div>}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default TermsConditions;
