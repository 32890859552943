import React, { useCallback } from "react";
import { useField } from "react-final-form";
import { Form } from "react-bootstrap";
import { Multiselect } from "multiselect-react-dropdown";

const CustomMultiSelect = ({ name, options, multiselectRef, ...config }) => {
  const { input, meta } = useField(name, { ...config });

  const handleChange = useCallback(value => {
    input.onChange(value);
    input.onBlur(value);
  });

  return (
    <>
      <Form.Label>Select Service</Form.Label>
      <Multiselect
        ref={multiselectRef}
        options={options}
        onSelect={handleChange}
        onRemove={handleChange}
        displayValue="title"
        style={{
          chips: { background: "#5ec19b" },
          option: {
            background: "#5ec19b",
            color: "#ffffff"
          }
        }}
      />
      {meta.error && meta.touched && (
        <span className="error">{meta.error}</span>
      )}
    </>
  );
};
//
export { CustomMultiSelect };
