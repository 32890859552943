import {
  REGISTER_USER,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT
} from "../constants";

const user = JSON.parse(localStorage.getItem("user"));

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

const AuthReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_USER:
      return {
        ...state,
        isLoading: true,
        isLoggedIn: false,
        register: payload
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: true,
        user: payload
      };
    case REGISTER_FAIL:
      return {
        ...state,
        isLoading: false,
        isLoggedIn: false
      };
    case LOGIN_USER:
      return {
        ...state,
        isLoggedIn: false,
        user: payload
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null
      };
    case LOGOUT:
      localStorage.removeItem("user");
      return {
        ...state,
        isLoggedIn: false,
        user: null
      };
    default:
      return state;
  }
};
// const AuthReducer = (
//   state = initialState,
//   { type = null, payload = null } = {}
// ) => {
//   switch (type) {
//     case REGISTER_SUCCESS:
//       return { ...state, user: payload };
//     case REGISTER_FAIL:
//       return { ...state, user: payload };

//     case types.LOGIN_SUCCESS:
//       return { ...state, user: payload };
//     case types.LOGIN_FAIL:
//       return { ...state, user: payload };
//     default:
//       return state;
//   }
// };
export default AuthReducer;
