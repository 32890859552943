import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Col, Row, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import axios from "axios";
import { Link } from "react-router-dom";

import Slider from "../../components/slider/slider";
import GoogleReview from "../../components/google-review/google-review";
import "./location.scss";

import { getSubCategoryList } from "../../redux/action/SubCategories";
import SEO from "../../components/seo";
import { baseUrl } from "../../ennviroment";

const Location = () => {
  const dispatch = useDispatch();
  const [locationHours, setLocationHours] = useState([]);
  const [address, setAddress] = useState("");
  const [metaTags, setMetaTags] = useState({});
  const [nearby, setNearBy] = useState("");

  const { subCategoryList } = useSelector((state) => {
    return state.subcategories;
  });

  useEffect(() => {
    axios
      .get(
        `${baseUrl}api/v1/location/get-locationhours?location=Jackson Heights`
      )
      .then((res) => {
        setLocationHours(res.data.data.data[0].day_details);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  //54.190.192.105:5036/api/v1/page/get-page?slug=location1-address
  useEffect(() => {
    axios
      .get(
        `${baseUrl}api/v1/page/get-page?slug=location1-address`
      )
      .then((res) => {
        setMetaTags(res.data.data.data[0]);
        setAddress(res.data.data.data[0].description);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `${baseUrl}api/v1/page/get-page?slug=location1-near-by-station`
      )
      .then((res) => {
        setNearBy(res.data.data.data[0].description);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    dispatch(getSubCategoryList());
  }, [dispatch]);

  const findDate = () => {
    let days = [];
    for (let i = 0; i < 7; i++) {
      days.push(moment().add(i, "days").format("dddd, MMMM Do "));
    }

    let newData = days.map((day) => {
      let splitedDay = day.split(",");
      let newDay = {
        day: splitedDay[0],
        date: splitedDay[1],
      };
      return newDay;
    });
    let schedule = newData.map((d) => {
      let time = "Closed";
      for (let i = 0; i < locationHours?.length; i++) {
        if (locationHours[i].day === d.day) {
          if (locationHours[i].start_time || locationHours[i].end_time)
            time =
              locationHours[i].start_time + " " + locationHours[i].end_time;
        }
      }
      return (
        <p>
          <span>{d.day}</span>
          <span>{d.date}</span>
          <span>{time}</span>
        </p>
      );
    });

    return schedule;
  };

  return (
    <>
      {metaTags&& Object.keys(metaTags).length ? (
        <SEO
          title={metaTags.meta_title}
          description={metaTags.meta_description}
          keywords={metaTags.meta_keywords}
        />
      ) : null}
      <section className="locaton-banner">
        <Container>
          <div className="location-outer">
            <Row>
              <Col>
                <h2 className="place-heading">Bobby-Tariq Tutoring Center</h2>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col md={6}>
                <div
                  className="location-box"
                  dangerouslySetInnerHTML={{
                    __html: address,
                  }}
                ></div>
              </Col>
              <Col md={6}>
                <div className="prep-outer mx-auto">
                  {subCategoryList.data && subCategoryList.data.length > 0
                    ? subCategoryList.data.slice(0, 3).map((val) => (
                        <Link to={`/${val.slug}`}>
                          <div key={`location1_${val.slug}`} className="boxes">
                            <h3>{val.title}</h3>
                            <p>In Person | Live Online</p>
                            <a href="#">
                              <img
                                src="/images/green-right-arrow.svg"
                                alt="icon"
                              />
                            </a>
                          </div>
                        </Link>
                      ))
                    : null}
                </div>
                {/* <div className="prep-outer mx-auto">
{subCategoryList?.data?.map((data, index) => {
if (index < 3) {
return (
<div className="boxes" key={`location_${index}`}>
<h3>{data.title}</h3>
<p>In Person | Live Online</p>
<Link to={`/service/${data._id}`}>
<img
src="/images/green-right-arrow.svg"
alt="icon"
/>
</Link>
</div>
);
}
})}
</div> */}
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <section className="nearby-section">
        <Container>
          <Row className="justify-content-center mt-4">
            <Col sm={11}>
              <div
                className="nearby-station"
                dangerouslySetInnerHTML={{
                  __html: nearby,
                }}
              ></div>
              <Row>
                <Col md={6}>
                  <div className="map-point">
                    <div className="mapouter">
                      <div className="gmap_canvas">
                        <iframe
                          width="100%"
                          height="325"
                          id="gmap_canvas"
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.586236550533!2d-73.89361268459398!3d40.7491291793279!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25f0750ad8435%3A0xac9242eebe218d19!2sBobby-Tariq%20Tutoring%20Center!5e0!3m2!1sen!2sin!4v1623667872201!5m2!1sen!2sin"
                          frameBorder="0"
                          scrolling="no"
                          marginHeight="0"
                          marginWidth="0"
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="location-hours">
                    <h3>
                      <img
                        src="/images/clock-icon.svg"
                        alt="icon"
                      />
                      Location hours
                    </h3>
                    {findDate()}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="slider-section">
        <Container>
          <Row>
            <Col sm={12}>
              <h2 className="section-heading">Students Results</h2>
              <Slider params={{ onpage: "home" }} />
            </Col>
          </Row>
        </Container>
      </section>
      {/* <GoogleReview review={reviewList} /> */}
      <GoogleReview location="Jackson Heights" />

      {/* <FaqAccordion list={faqList} /> */}
      <section className="green-search">
        <Container>
          <Row>
            <Col sm={12}>
              <div className=" green-container">
                <Link to="/contact-us" className="contact-us-btn">
                  Contact Us
                </Link>
                {/* <h1 className="watermark-text">Contact Us</h1> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Location;
