import React, { useEffect, useState, useCallback } from "react";
import { Col, Button, Row, Container } from "react-bootstrap";
import { Form as FinalForm, Field } from "react-final-form";
import { useHistory } from "react-router-dom";
import Sidebar from "../sidebar/sidebar";
import { initialValues } from "./formSetup";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserDetailsById,
  updatePersonalInfo
} from "../../../redux/action/User";
import "./personal-info.scss";
const PersonalInfo = () => {
  const [readOnly, setReadOnly] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector(state => state.auth);

  const { userDetails } = useSelector(state => state.user);
  useEffect(() => {
    if (user) {
      dispatch(getUserDetailsById(user._id));
    }
  }, [user._id]);

  const handleUpdate = useCallback(values => {
    values.userId = values._id;
    delete values._id;
    dispatch(
      updatePersonalInfo({
        values,
        history: history
      })
    );
    setReadOnly(prev => !prev);
  }, []);

  const handleReadOnly = () => {
    setReadOnly(prev => !prev);
  };
  const userData =
    userDetails !== "undefined" &&
    Object.keys(userDetails).length &&
    userDetails?.data.length
      ? userDetails.data[0]
      : null;

  return (
    <>
      <section className="user-home">
        <Container fluid>
          <Row>
            <Col md={4}>
              <Sidebar />
            </Col>
            <Col md={7}>
              <div className="wraper">
                <div className="modul-header mb-4">
                  <h2>Personal Info</h2>
                  <p>
                    Manage student info, parent info, billing addresses, <br />
                    and phone number.
                  </p>
                </div>
                {userData ? (
                  <div className="edit-personal">
                    <FinalForm
                      initialValues={initialValues(userData)}
                      onSubmit={handleUpdate}
                      render={({
                        handleSubmit,
                        reset,
                        submitting,
                        pristine,
                        values
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <div className="personal-box">
                            <div className="upper-header">
                              <h3>Student Information</h3>
                              {readOnly ? (
                                // <a href="#">Edit Personal Info</a>
                                <a
                                  // className="payment-submit"
                                  onClick={handleReadOnly}
                                  // disabled={submitting}
                                >
                                  Edit Personal Info
                                </a>
                              ) : (
                                <Button
                                  type="submit"
                                  className="payment-submit btn-default"
                                  disabled={submitting}
                                >
                                  Update
                                </Button>
                              )}
                            </div>
                            <div className="inner-body">
                              <div className="form-group">
                                <label>First Name</label>
                                <Field
                                  name="firstName"
                                  type="text"
                                  component="input"
                                  value={values.firstName}
                                  readOnly={readOnly}
                                />
                              </div>
                              <div className="form-group">
                                <label>Last Name</label>
                                <Field
                                  name="lastName"
                                  type="text"
                                  component="input"
                                  value={values.lastName}
                                  readOnly={readOnly}
                                />
                              </div>
                              <div className="form-group">
                                <label>Email</label>
                                <Field
                                  name="email"
                                  type="email"
                                  component="input"
                                  value={values.email}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                          <div className="personal-box">
                            <div className="upper-header">
                              <h3>Parent Information</h3>
                            </div>
                            <div className="inner-body">
                              <div className="form-group">
                                <label>First Name</label>
                                <Field
                                  name="parent_details.firstName"
                                  type="text"
                                  component="input"
                                  value={values.parent_details.firstName}
                                  readOnly={readOnly}
                                />
                              </div>
                              <div className="form-group">
                                <label>Last Name</label>
                                <Field
                                  name="parent_details.lastName"
                                  type="text"
                                  component="input"
                                  value={values.parent_details.lastName}
                                  readOnly={readOnly}
                                />
                              </div>
                              <div className="form-group">
                                <label>Email</label>
                                <Field
                                  name="parent_details.email"
                                  type="email"
                                  component="input"
                                  value={values.parent_details.email}
                                  readOnly
                                />
                                {/* {`${userData.parent_details.firstName} ${userData.parent_details.lastName}`} */}
                              </div>
                              <div className="form-group">
                                <label>Phone</label>
                                <Field
                                  name="parent_details.phone"
                                  type="tel"
                                  component="input"
                                  value={values.parent_details.phone}
                                  readOnly={readOnly}
                                />
                                {/* {userData.parent_details.phone} */}
                              </div>
                            </div>
                          </div>
                          <div className="personal-box">
                            <div className="upper-header">
                              <h3>Billing Address</h3>
                            </div>
                            <div className="inner-body">
                              <div className="form-group">
                                <Field
                                  name="parent_details.address.apartment"
                                  type="text"
                                  component="input"
                                  value={
                                    values.parent_details.address.apartment
                                  }
                                  readOnly={readOnly}
                                />
                              </div>
                              <div className="form-group">
                                <Field
                                  name="parent_details.address.street"
                                  type="text"
                                  component="input"
                                  value={values.parent_details.address.street}
                                  readOnly={readOnly}
                                />
                              </div>
                              <div className="form-group">
                                <Field
                                  name="parent_details.address.city"
                                  type="text"
                                  component="input"
                                  value={values.parent_details.address.city}
                                  readOnly={readOnly}
                                />
                              </div>
                              <div className="form-group">
                                <Field
                                  name="parent_details.address.zipcode"
                                  type="text"
                                  component="input"
                                  value={values.parent_details.address.zipcode}
                                  readOnly={readOnly}
                                />
                              </div>
                              <div className="form-group">
                                <Field
                                  name="parent_details.address.state"
                                  type="text"
                                  component="input"
                                  value={values.parent_details.address.state}
                                  readOnly={readOnly}
                                />
                              </div>
                            </div>
                          </div>
                        </form>
                      )}
                    />
                  </div>
                ) : (
                  <p>No Data Found</p>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default PersonalInfo;
