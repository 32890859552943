import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Sidebar from "../sidebar/sidebar";
import { useSelector, useDispatch } from "react-redux";
import { getUserDetailsById } from "../../../redux/action/User";

import "./user-home.scss";
const UserHome = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const { userDetails } = useSelector(state => state.user);

  useEffect(() => {
    if (user) {
      dispatch(getUserDetailsById(user._id));
    }
  }, [user._id]);
  const userData =
    userDetails !== "undefined" &&
    Object.keys(userDetails).length &&
    userDetails?.data.length
      ? userDetails.data[0]
      : null;
  return (
    <>
      <section className="user-home">
        <Container fluid>
          <Row>
            <Col md={4}>
              <Sidebar />
            </Col>
            <Col md={7}>
              <div className="wraper">
                <Row>
                  <Col>
                    <div className="modul-header">
                      {userData ? (
                        <h2>
                          Welcome,
                          {`${userData.firstName} ${userData.lastName}`}
                        </h2>
                      ) : null}

                      <p>
                        Welcome to your Bobby-Tariq® account. Here you can
                        manage your
                        <br />
                        registration forms, order history, payment details, and
                        security.
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <div className="pops-box">
                      <div className="left">
                        <h2>Personal Info</h2>
                        <p>
                          Manage student info, parent info, billing addresses,
                          and phone number.
                        </p>
                        <Link to="/personal-info">Manage Personal Info</Link>
                        {/* <a href="#">Manage Personal Info</a> */}
                      </div>
                      <div className="right">
                        <img
                          src="../images/personal-data.svg"
                          className="img-fluid"
                          alt="data"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="pops-box">
                      <div className="left">
                        <h2>Order History</h2>
                        <p>
                          Access your registration form, purchase history, and
                          payment status.
                        </p>
                        <Link to="/order-history">Manage Order History</Link>
                      </div>
                      <div className="right">
                        <img
                          src="../images/order-history.svg"
                          className="img-fluid"
                          alt="order"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="pops-box">
                      <div className="left">
                        <h2>Payment Method</h2>
                        <p>
                          Manage your credit card & debit card saved on the
                          account. Update the payment method as needed.
                        </p>
                        <Link to="/payment-method">Manage Payment Method</Link>
                      </div>
                      <div className="right">
                        <img
                          src="../images/credit-card.svg"
                          className="img-fluid"
                          alt="card"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="pops-box">
                      <div className="left">
                        <h2>Security</h2>
                        <p>
                          Manage your login details such as user name and
                          password.
                        </p>
                        <Link to="/security">Manage Security</Link>
                      </div>
                      <div className="right">
                        <img
                          src="../images/securty.svg"
                          className="img-fluid"
                          alt="securty"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default UserHome;
