import { SERVICE_REQUEST, FAQ_REQUEST, REVIEW_REQUEST } from "../constants";
export const getService = id => {
  return {
    type: SERVICE_REQUEST,
    payload: { id }
  };
};

export const getFAQ = id => {
  return {
    type: FAQ_REQUEST,
    payload: { id }
  };
};

export const getGoogleReview = payload => {
  return {
    type: REVIEW_REQUEST,
    payload
  };
};
