import React, { useEffect, useState, useCallback } from "react";
import { Col, Button, Row, Container } from "react-bootstrap";
import { Form as FinalForm, Field } from "react-final-form";
import { useHistory } from "react-router-dom";
import Sidebar from "../sidebar/sidebar";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserDetailsById,
  updatePersonalInfo
} from "../../../redux/action/User";
import { logout } from "../../../redux/action/Auth";
import "./security.scss";
const Security = () => {
  const [readOnly, setReadOnly] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector(state => state.auth);

  const { userDetails } = useSelector(state => state.user);
  useEffect(() => {
    if (user) {
      dispatch(getUserDetailsById(user._id));
    }
  }, [user, dispatch]);

  const handleUpdate = useCallback(values => {
    dispatch(
      updatePersonalInfo({
        values,
        history: history
      })
    );
    dispatch(logout());
  }, []);

  const handleReadOnly = () => {
    setReadOnly(prev => !prev);
  };
  const userData =
    userDetails !== "undefined" &&
    Object.keys(userDetails).length &&
    userDetails?.data.length
      ? userDetails.data[0]
      : null;
  return (
    <>
      <section className="user-home">
        <Container fluid>
          <Row>
            <Col md={4}>
              <Sidebar />
            </Col>
            <Col md={7}>
              <div className="wraper">
                <div className="modul-header mb-4">
                  <h2>Security</h2>
                  <p>
                    Manage your login details such as user
                    <br /> name and password.
                  </p>
                </div>
                <div className="edit-personal">
                  {userData ? (
                    <div className="edit-personal">
                      <FinalForm
                        initialValues={{
                          userId: userData._id,
                          email: userData.email || "",
                          password: readOnly ? "*******" : ""
                        }}
                        onSubmit={handleUpdate}
                        render={({
                          handleSubmit,
                          reset,
                          submitting,
                          pristine,
                          values
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <div className="personal-box">
                              <div className="upper-header">
                                <h3>Login</h3>
                                {readOnly ? (
                                  <a onClick={handleReadOnly}>
                                    Edit Login Info
                                  </a>
                                ) : (
                                  <Button
                                    type="submit"
                                    className="payment-submit btn-default"
                                    disabled={submitting}
                                  >
                                    Update
                                  </Button>
                                )}
                              </div>
                              <div className="inner-body">
                                <div className="form-group">
                                  <label>Email</label>
                                  <Field
                                    name="email"
                                    type="email"
                                    component="input"
                                    value={values.email}
                                    readOnly
                                  />
                                </div>
                                <div className="form-group">
                                  <label>Password</label>
                                  <Field
                                    name="password"
                                    type="text"
                                    component="input"
                                    value={values.password}
                                    readOnly={readOnly}
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        )}
                      />
                    </div>
                  ) : (
                    <p>No Data Found</p>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Security;
