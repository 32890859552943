import React, { useState, useEffect } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Button,
  Col,
  Container,
  Row,
} from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../redux/action/Auth";
import { getSubCategoryList } from "../../redux/action/SubCategories";
import axios from "axios";
import "./header.scss";
import PersonIcon from "../../images/outline/Person";
import { shiftCart } from "../../redux/action/Cart";
import { extractPhoneNumber, getRanges } from "../../utils/helpers";
import {baseUrl} from '../../ennviroment'

const SLUGS = {
  Jamaica: "/jamaica",
  "Jackson Heights": "/jackson-heights",
};
const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [search, setSearch] = useState(false);
  const [navExpanded, setNavExpanded] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [talkToSales, setTalkToSales] = useState([]);
  const [locations, setLocations] = useState([]);

  //Api call for locations
  useEffect(() => {
    axios
      .get(
        `${baseUrl}api/v1/talktosales/get-locationhours`
      )
      .then((res) => {
        setTalkToSales(res?.data?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(`${baseUrl}api/v1/location/get-locationhours`)
      .then((res) => {
        setLocations(
          res?.data?.data?.data?.map((node) => {
            return {
              ...node,
              slug: SLUGS[node.location],
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const { subCategoryList } = useSelector((state) => {
    return state.subcategories;
  });

  const searchHandler = () => {
    setSearch(true);
  };
  const searchHandlerClose = () => {
    setSearch(false);
  };

  const toggelNav = () => {
    setNavExpanded((n) => !n);
  };

  document.addEventListener("click", (evt) => {
    if (
      (evt.target.id === "navbarId" ||
        evt.target.parentNode.id === "navbarId") &&
      evt.target.parentNode.className !== "navbar-toggler collapsed"
    ) {
      setNavExpanded(true);
    } else if (evt.target.className === "dropdown-toggle nav-link") {
      return;
    } else {
      setNavExpanded(false);
    }
  });

  useEffect(() => {
    dispatch(getSubCategoryList());
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 700);
    });
  }, []);

  const handleOnSelect = (item) => {
    history.push({
      pathname: `/${item.slug}`,
      state: { id: item._id },
    });
  };

  const { items: cartItems } = useSelector((state) => {
    return state.cart;
  });

  const { isLoggedIn, user } = useSelector((state) => state.auth);

  const handleLogOut = () => {
    //dispatch(logout());
    if (cartItems.length && user?._id) {
      dispatch(shiftCart({ userId: user._id, cart: cartItems, logOut: true }));
    } else {
      dispatch(logout());
    }
    history.push("/");
  };

  const Div = ({ children }) => {
    return <div className="talk-to-sales-wrapper">{children}</div>;
  };
  return (
    <>
      <header className="header">
        <Container>
          <Row>
            <Col sm={12}>
              <div className="flex-ouer">
                <div className="site-logo">
                  <Link to="/">
                    <img
                      src="/images/logo.svg"
                      className="img-fluid"
                      alt="LOGO"
                    />
                    {/* <img
                      src="/images/logo.png"
                      className="img-fluid"
                      alt="LOGO"
                    /> */}
                  </Link>
                </div>
                <Navbar
                  collapseOnSelect
                  color="faded"
                  expand="lg"
                  expanded={navExpanded}
                  //expanded={window.screen.width > 991 ? "" : navExpanded}
                  // expand={window.screen.width > 991 ? "lg" : navexpanded}
                  // onToggle={toggelNav}
                >
                  <Navbar.Toggle
                    id="navbarId"
                    aria-controls="basic-navbar-nav"
                    onClick={() =>
                      setNavExpanded(navExpanded ? false : "navExpanded")
                    }
                  />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                      <NavDropdown
                        title="SAT"
                        renderMenuOnMount={true}
                        id="collasible-nav-dropdown"
                      >
                        <NavDropdown.Item
                          as={Link}
                          to={{
                            pathname: `/sat-prep`,
                            state: { id: "60c1cfb81fb194696be19fd1" },
                          }}
                          onSelect={toggelNav}
                        >
                          SAT Prep
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to={{
                            pathname: `/sat-private-tutoring`,
                            state: { id: "60c2d1d4c3fc2b7347068a5c" },
                          }}
                          onSelect={toggelNav}
                        >
                          SAT Private Tutoring
                        </NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown
                        title="SHSAT"
                        id="shsat"
                        renderMenuOnMount={true}
                      >
                        <NavDropdown.Item
                          as={Link}
                          to={{
                            pathname: `/shsat-prep`,
                            state: { id: "60c1d1821fb194696be19fd6" },
                          }}
                          onSelect={toggelNav}
                        >
                          SHSAT Prep
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to={{
                            pathname: `/shsat-private-tutoring`,
                            state: { id: "60c2d1eac3fc2b7347068a6a" },
                          }}
                          onSelect={toggelNav}
                        >
                          SHSAT Private Tutoring
                        </NavDropdown.Item>
                      </NavDropdown>
                      <NavDropdown
                        title="9th Grade SHSAT"
                        id="grad-shsat"
                        renderMenuOnMount={true}
                      >
                        <NavDropdown.Item
                          as={Link}
                          to={{
                            pathname: `/9th-grade-shsat-prep`,
                            state: { id: "60c1f8ec64aec620037821d8" },
                          }}
                          onSelect={toggelNav}
                        >
                          9th Grade SHSAT Prep
                        </NavDropdown.Item>
                        <NavDropdown.Item
                          as={Link}
                          to={{
                            pathname: `/9th-grade-shsat-private-tutoring`,
                            state: { id: "60c2d200c3fc2b7347068a71" },
                          }}
                          onSelect={toggelNav}
                        >
                          9th Grade SHSAT Private Tutoring
                        </NavDropdown.Item>
                      </NavDropdown>
                      {/* <a
                        href="https://store.bobbytariq.com/"
                        target="_blank"
                        rel="noreferrer"
                        className="nav-link"
                      >
                        Book Store
                      </a> */}
                      <NavDropdown
                        title="Locations"
                        id="more"
                        renderMenuOnMount={true}
                      >
                        {
                          locations?.map(({location,slug})=>{
                            return(
                              <NavDropdown.Item
                              as={Link}
                              to={slug}
                              onSelect={toggelNav}
                            >
                              {location}
                            </NavDropdown.Item>
                            )
                          })
                        }
                       
                      </NavDropdown>
                      <Link to="/blog" className="nav-link" onClick={toggelNav}>
                        Blog
                      </Link>
                      <Link
                        to="/contact-us"
                        className="nav-link"
                        onClick={toggelNav}
                      >
                        Contact Us
                      </Link>
                      <NavDropdown
                        title="Talk to Sales"
                        id="more"
                        renderMenuOnMount={true}
                      >
                        <NavDropdown.Item
                          as={Div}
                          className="talk-to-sales-wrapper"
                        >
                          {talkToSales.map(
                            (
                              { location, status, phone, day_details },
                              index
                            ) => {
                              if (status === "Active") {
                                const ranges = getRanges(day_details);
                                const tel = extractPhoneNumber(phone);
                                return (
                                  <>
                                    <div className="talk-item-wrapper">
                                      {location && (
                                        <div className="talk-item">
                                          <span className="bold location">
                                            {location}
                                          </span>
                                        </div>
                                      )}
                                      {phone && (
                                        <div className="talk-item">
                                          <a
                                            href={`tel:${tel}`}
                                            className="phone-anchor-item"
                                          >
                                            &nbsp;{phone}
                                          </a>
                                        </div>
                                      )}
                                      {ranges?.length > 0 &&
                                        ranges?.map((node, i) => {
                                          return (
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: node,
                                              }}
                                              key={i}
                                              className="talk-item"
                                            ></div>
                                          );
                                        })}
                                    </div>
                                    {index <= talkToSales?.length - 2 && (
                                      <hr className="hr-talk-item" />
                                    )}
                                  </>
                                );
                              }
                            }
                          )}
                        </NavDropdown.Item>
                      </NavDropdown>
                      <Link
                        to={{
                          pathname: "/sign-in",
                          state: { location: location.pathname },
                        }}
                        className="mobile-signin"
                        onClick={toggelNav}
                      >
                        Sign In
                      </Link>
                      {/* <Link
                        to="/signin"
                        className="mobile-signin"
                        onClick={toggelNav}
                      >
                        Sign In
                      </Link> */}
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
                <div className="search-box">
                  {isLoggedIn ? (
                    <NavDropdown
                      title={<PersonIcon />}
                      renderMenuOnMount={true}
                    >
                      <NavDropdown.Item onSelect={toggelNav}>
                        <Link to="/user-home">My Account</Link>
                      </NavDropdown.Item>
                      <NavDropdown.Item>
                        <Link onClick={handleLogOut}>Logout</Link>
                      </NavDropdown.Item>
                    </NavDropdown>
                  ) : (
                    // <Link onClick={handleLogOut}>Logout</Link>
                    <Link
                      to={{
                        pathname: "/sign-in",
                        state: { location: location.pathname },
                      }}
                      className="web-signin"
                    >
                      Sign In
                    </Link>
                  )}
                  <Button
                    className="top-search"
                    variant="outline-none"
                    onClick={searchHandler}
                  >
                    <img
                      src="/images/search-icon.svg"
                      alt="Search"
                    />
                  </Button>
                  <Link to="/cart" className="cart-btn">
                    <span className="cart-span">{cartItems?.length}</span>
                    <img
                      src="/images/add-to-cart.svg"
                      alt="add-to-cart"
                    />
                  </Link>
                  {/* <a href="#" className="cart-btn">
                    <img src="/images/add-to-cart.svg" alt="add-to-cart" />
                  </a> */}
                  <form className={search ? "search-boxin" : "hide-box"}>
                    <div className="input-group">
                      <div style={{ width: "100%" }}>
                        <ReactSearchAutocomplete
                          items={subCategoryList.data}
                          fuseOptions={{
                            keys: ["title"],
                            includeScore: true,
                            includeMatches: true,
                          }}
                          showIcon={false}
                          placeholder="Search"
                          resultStringKeyName="title"
                          onSelect={handleOnSelect}
                          autoFocus
                        />
                        <input
                          type="button"
                          className="search-here btn"
                          value="Find Your Prep"
                        />
                      </div>
                      <div
                        className="close-sec"
                        id="close-srch"
                        onClick={searchHandlerClose}
                      >
                        x
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </header>
    </>
  );
};
export default Header;
{
  /* <div style={{ display: "inline-block" }} renderMenuOnMount={true}>
  <SettingsIcon />
</div>; */
}
