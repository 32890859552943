// export const required = value => (value ? undefined : "Required");
export const required = value => {
  return value ? undefined : "Required";
};

export const arrayRequired = value => {
  return value.length ? undefined : "Required";
};
export const isValidEmail = value => {
  return /\S+@\S+\.\S+/.test(value) ? undefined : "Invalid Email";
};
export const mustBeNumber = value =>
  isNaN(value) ? "Must be a number" : undefined;

export const digitccValidate = value => {
  return /^.{15,16}$/.test(value) ? undefined : "Must be 15 or 16 digit number";
};

export const digitValidate = value => {
  return /^.{3,4}$/.test(value) ? undefined : "Must be 3 or 4 digit number";

  // return /^\d{4}$/.test(value) ? undefined : "Must be 4 digit number";
};
export const phoneValidate = value => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, "");
  if (onlyNums.length === 4) {
    return onlyNums.replace(/(\d{3})/, "($1)");
  } else if (onlyNums.length >= 3 && onlyNums.length <= 6) {
    return onlyNums.replace(/(\d{3})(\d{3})/, "($1) $2");
  } else if (onlyNums.length >= 7 && onlyNums.length <= 10) {
    return onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  } else return onlyNums;
};

export const phoneMaxLength = value => {
  return /^[\d() -]{14}$/.test(value) ? undefined : "Invalid";
};

// /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/gm.test(value);
// const digitValidate = min => value =>
//   isNaN(value) || value >= min ? undefined : `Should be greater than ${min}/^(\d{10}|\d{12})$/`;

export const expiry = value =>
  /^(0[1-9]|1[0-2])\/?([0-9]{4}|[0-9]{2})$/gm.test(value)
    ? undefined
    : "Invalid Format";

export const normalizeExpiry = value => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, "");

  if (onlyNums.length >= 3 && onlyNums.length <= 4)
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}`;
  else if (onlyNums.length > 4)
    return `${onlyNums.slice(0, 2)}/${onlyNums.slice(2, 4)}`;
  else return onlyNums;
};

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);
